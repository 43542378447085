.styled-checkbox__label {
  position: relative;
  top: 4px;
  user-select: none;
}
.styled-checkbox__label.styled-checkbox__label--not-valid {
  color: red;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-top: 8px;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.styled-checkbox:hover + label:before {
  background: #f35429;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #f35429;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 12px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
    4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
