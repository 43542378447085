/*----------------------------------------------------------------------------*/
/*Utils*/
/*----------------------------------------------------------------------------*/

.float-right {
  float: right;
}

.relative {
  position: relative;
}

.hidden {
  display: none !important;
}

.grid__item h2 {
  margin-top: 0px;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.flex__elememt {
  width: 100%;
}

.flex > button {
  margin: 5px;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 10000000;
}

/*----------------------------------------------------------------------------*/
/* UX */
/*----------------------------------------------------------------------------*/
.loading {
  pointer-events: none;

  opacity: 0.8;
}

.transparent {
  opacity: 0 !important;
  pointer-events: none;
}

.error {
  font-size: 13px;
  margin-bottom: 20px;
  color: rgb(229, 79, 0);
}

.error.error--boundardy {
  padding: 10px;
  display: inline-block;
}

/*.error:empty {
    display: none;
}
*/
/*----------------------------------------------------------------------------*/
/* Typography */
/*----------------------------------------------------------------------------*/
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.text-medium-light {
  color: rgba(0, 0, 0, 0.4);
}

.text-light {
  color: rgba(0, 0, 0, 0.25);
}

.text-light-bg {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.thin {
  font-weight: 300;
}

/*----------------------------------------------------------------------------*/
/* Layout */
/*----------------------------------------------------------------------------*/
.clear:before,
.clear:after {
  content: " ";
  display: table;
}
.clear:after {
  clear: both;
}
.clear {
  *zoom: 1;
}
