.cta--margin-right {
  margin-right: 10px;
}

.budget-monitor_play-stop-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.search-term__item {
  display: inline-block;
  margin-right: 5px;
  background: lightgray;
}

.generate-clip__wrapper {
  margin-top: 5px;
  display: inline-block;
}

.generate-clip__wrapper select {
  border: 1px solid transparent;
  border-radius: 3px;
  height: 25px;
  line-height: 25px;
  width: 150px;
  padding-left: 5px;
  user-select: none;
}

.generate-clip:hover {
  background: #0fa944;
}

.generate-clip span {
  display: inline-block;
  margin-left: 5px;
}

.generate-clip {
  transition: 0.2s;
  background: #1fd75f;
  color: white;
  border: 1px solid transparent;
  border-radius: 3px;
  height: 25px;
  padding: 0px;
  line-height: 21px;
  width: 150px;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.generate-clip:active {
  transform: translateY(2px);
}
.fetch-preview:hover {
  background: black;
}

.fetch-preview span {
  display: inline-block;
  margin-left: 5px;
}

.fetch-preview {
  transition: 0.2s;
  background: rgb(87, 87, 87);
  color: white;
  border: 1px solid transparent;
  border-radius: 3px;
  height: 25px;
  padding-right: 5px;
  line-height: 21px;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.fetch-preview:active {
  transform: translateY(2px);
}

.btn__change {
  font-size: 12px;
  color: #adadad;
  text-decoration: underline;
}

.preview__search-term {
  position: absolute;
  top: 25px;
  left: 10px;
  width: 300px;
  background: yellow;
  border: 1px solid black;
  padding: 10px;
}

.small-badge.small-badge--error {
  background: #e74c3c;
  color: white;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 11px;
  z-index: 1000000;
}

.small-badge-wrapper {
  position: absolute;
  top: 0px;
  left: 40px;
}

.small-badge-static {
  background: #607d8b;
  color: white;
  padding: 5px;
  display: inline-block;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 11px;
  z-index: 1000000;
  margin-right: 10px;
  pointer-events: none;
  user-select: none;
}

.small-badge-static.small-badge-static--STRONG {
  background: #e74c3c;
}

.small-badge-static.small-badge-static--SUCESS {
  background: #16a085;
}

.small-badge-static.small-badge-static--BLUE {
  background: #2980b9;
}

.small-badge-static.small-badge-static--VIOL {
  background: #9b59b6;
}

.small-badge-static.small-badge-static--YELLOW {
  background: #ffcc00;
}

.small-badge {
  background: #607d8b;
  color: white;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 40px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 11px;
  z-index: 1000000;
}

.relative {
  position: relative;
}

.popup__mention {
  background-color: white;
  border: 1px solid #a73911;
  padding: 3px 10px;
  border-radius: 23px;
  display: inline-block;
  margin-bottom: 5px;
  color: #a73911;
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
}

.popup__btn {
  width: 100%;
  background-color: blue;
  color: white;
  border: none;
  padding: 4px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.popup-attention__wrappper.popup-attention__wrappper--small {
  margin-top: 10px;
  margin-right: 0px;
  width: 100%;
}

.popup-attention__wrappper.popup-attention__wrappper--small .cta.cta--inline.cta--attention {
  width: 100%;
  background-color: transparent;
  color: #2196f3;
  border: 1px solid #2196f3;
  padding: 0px 4px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  line-height: 20px;
  height: 20px;
  font-weight: 400;
}

.custom-select__single-value {
  width: 100%;
}

.custom-select__menu-list.custom-select__menu-list {
  justify-content: start;
}

.popup-attention__wrappper.popup-attention__wrappper--bottom {
  margin-top: 0px;
  margin-right: 0px;
  width: 100%;
  max-width: 150px;
}

.popup-attention__wrappper.popup-attention__wrappper--bottom .cta.cta--inline.cta--attention {
  width: 100%;
  background-color: transparent;
  color: #2196f3;
  border: 1px solid #2196f3;
  padding: 0px 4px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  line-height: 35px;
  height: 35px;
  font-weight: 400;
}

.popup-attention__wrappper.popup-attention__wrappper--bottom .popup-attention {
  position: absolute;
  bottom: 44px;
  transform: translate(0%, 0%);
  left: 0px;
}

.popup__mention.popup__mention--active {
  background-color: coral;
  color: #a73911;
}

.popup-attention__close {
  background: #f9f9f9;
  border-radius: 29px;
  color: gray;
  outline: none;
  cursor: pointer;
}

.popup-attention__input {
  border: 1px solid gray;
  border-radius: 3px;
  margin-top: 20px;
  outline: none;
  height: 60px;
  width: 100%;
  padding: 10px;
}

.cta--attention {
  background-color: blue;
  color: white;
}

.popup-attention__head {
  display: inline-block;
  margin-bottom: 10px;
}

.popup-attention__wrappper {
  display: inline-block;
}

.popup-attention__wrappper.popup-attention__wrappper--sidepanel .popup-attention {
  bottom: -10px;
  left: 150px;
}

.popup-attention {
  position: absolute;
  bottom: 44px;
  transform: translate(-50%, 100%);
  left: -170px;
  width: 300px;
  min-height: 200px;
  background: white;
  z-index: 10000;
  padding: 15px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.2);
}

.budget-monitor_instagram-icon {
  position: absolute;
  top: 10px;
  left: 25px;
  cursor: pointer;
}

.table-badget {
  display: block;
  padding: 2px 5px;
  background: #dedede;
  border-radius: 14px;
  margin-bottom: 2px;
  font-size: 11px;
}

.table-badget.table-badget--FACEBOOK_AD_RECOMMENDATIONS {
  background: #f1c40f;
}

.table-badget.table-badget--GOOGLE_POLICY_ISSUE,
.table-badget.table-badget--YOUTUBE_CAMPAIGN_PAUSED,
.table-badget.table-badget--GDN_CAMPAIGN_PAUSED,
.table-badget.table-badget--FB_CAMPAIGN_PAUSED,
.table-badget.table-badget--GOOGLE_POLICY_MISLEADING_AD_DESIGN {
  background: #f1c40f;
}

.table-badget.table-badget--NO_TARGETING_OPTIONS_FACEBOOK,
.table-badget.table-badget--NO_TARGETING_OPTIONS_INSTAGRAM,
.table-badget.table-badget--TOO_MUCH_SPENT,
.table-badget.table-badget--NO_TARGETING_OPTIONS_TWITTER,
.table-badget.table-badget--NO_TARGETING_OPTIONS_REDDIT,
.table-badget.table-badget--NO_TARGETING_OPTIONS_GOOGLE-DISPLAY,
.table-badget.table-badget--NO_TARGETING_OPTIONS_YOUTUBE,
.table-badget.table-badget--NO_TARGETING_OPTIONS_EBAY_KLEINANZEIGEN,
.table-badget.table-badget--NO_TARGETING_OPTIONS_GOOGLE_SEARCH,
.table-badget.table-badget--FB_LEARNING_FAILED,
.table-badget.table-badget--NO_CLICKS_AT_ALL,
.table-badget.table-badget--NO_APPLICANTS_YET,
.table-badget.table-badget--URL_IS_BROKEN_404,
.table-badget.table-badget--NO_TARGETING_OPTIONS_YOUTUBE,
.table-badget.table-badget--INVALID_LOCATION_FACEBOOK {
  background: #ff605f;
}

.table-badget.table-badget--NO_FACEBOOK_ID_SET,
.table-badget.table-badget--NO_SPOTIFY_ID_SET,
.table-badget.table-badget--NO_INSTAGRAM_ID_SET,
.table-badget.table-badget--NO_YOUTUBE_ID_SET,
.table-badget.table-badget--NO_TWITTER_ID_SET,
.table-badget.table-badget--NO_PINTEREST_ID_SET,
.table-badget.table-badget--NO_REDDIT_ID_SET,
.table-badget.table-badget--NO_SNAPCHAT_ID_SET,
.table-badget.table-badget--NO_TIKTOK_ID_SET,
.table-badget.table-badget--NO_INDEED_ID_SET,
.table-badget.table-badget--NO_LINKEDIN_ID_SET,
.table-badget.table-badget--NO_GOOGLE_DISPLAY_ID_SET,
.table-badget.table-badget--NO_GOOGLE_SEARCH_ID_SET,
.table-badget.table-badget--NO_EBAY_KLEINANZEIGEN_ID_SET,
.table-badget.table-badget--FACEBOOK_PAGE_OVERBUDGET,
.table-badget.table-badget--FACEBOOK_AD_ISSUE,
.table-badget.table-badget--BUDGET_SPENT_TOO_SLOW,
.table-badget.table-badget--TOO_MUCH_SPENT {
  background: #ffaaaa;
}

.table__filter-select-all {
  font-size: 9px;
  color: lightgray;
}

.campaign-read__location-change {
  background: #dbe0ff;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.campaign-read__location-change .input__list-item {
  background: #6c9aff;
  color: white;
}

.campaign-read__location-change input.input {
  margin-top: 10px;
  height: 35px;
  line-height: 35px;
}

.warning-box {
  background-color: #faf9f2;
  padding: 10px;
}

.table__filte-section-collapse {
  display: none;
  z-index: 100000;
}

.table__filte-section-collapse.table__filte-section-collapse--open {
  display: block;
  z-index: 1000000;
}

.table__filter-item-clear {
  font-weight: 600;
  color: #8066ff;
}

.table__filter-item-wrapper {
  display: inline-block;
  position: relative;
  height: 94px;
  position: relative;
  line-height: 89px;
}

.table__filter-item-list {
  position: absolute;
  top: 60px;
  left: 0px;
  line-height: 1.5em;
  background: white;
  padding: 20px 10px;
  width: 270px;
  min-height: 150px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 0 5px 3px #00000017;
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;
}

.table__filter-item-wrapper:hover {
  z-index: 10000000;
}

.table__filter-item-wrapper:hover .table__filter-item-list {
  display: block;
  pointer-events: all;
  opacity: 1;
  z-index: 10000000;
}

.table__filter-item-item {
  margin-bottom: 8px;
}

.btn__snooze {
  background: #2196f3;
  border: none;
  border-radius: 3px;
  color: white;
  padding: 2px 15px;
  position: relative;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
}

.btn__snooze:focus {
  top: 2px;
}

.preview__main-cta {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background: #69d439;
  border-radius: 4px;
  max-width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: white;
  width: 300px;
  cursor: pointer;
  transition: 0.3s all;
}

.preview__main-cta:hover {
  background: #438227;
}

.draft .slick-dots {
  bottom: initial;
  top: -40px;
}

.preview__history {
  list-style-type: none;
  padding: 0px;
}

.preview__history li {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.preview__close {
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 40px;
  opacity: 0.2;
  cursor: pointer;
}

.grid__history h1 {
  margin-top: 0;
  color: #38527c;
  font-size: 22px;
}

.preview__badge {
  display: inline-block;
  padding: 3px 8px;
  background: #d0d0d0;
  border-radius: 4px;
  margin-right: 10px;
  color: #989898;
}

.preview__textarea {
  width: 100%;
  min-height: 200px;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.externer-link {
  text-decoration: underline;
  color: blue;
}

.faq .q2 {
  font-size: 15px;
  line-height: 1.7em;
}

.faq .question {
  color: #2e79bb;
}

.faq h4 {
  font-weight: 600;
  font-size: 17px;
}

.faq a {
  color: #2e79bb;
  text-decoration: underline;
}

.check {
  max-width: 150px;
}

.lighter {
  color: #7d7d7d;
}

.paid {
  color: #1daf1c;
}

.unpaid {
  color: #cf0923;
}

.pending {
  color: #f0ad4e;
}

.table__tr-body td {
  position: relative;
}

.dashboard__count {
  min-width: 23px;
  display: inline-block;
}

.dashboard__diff {
  color: #1daf1c;
  margin-right: 5px;
}

.table__top td {
  position: relative;
  user-select: none;
}

.table__light-select.table__light-select--short {
  width: 100px;
}

.table__light-select {
  width: 254px;
  background: #e0e0e0;
  border: none;
  padding: 5px 10px;
  -webkit-appearance: none;
}

.table__light-select.table__light-select--strong {
  background: #ff6b6b;
}

td.active:before {
  content: "▼";
  font-size: 8px;
  position: absolute;
  top: 6px;
  right: 10px;
  color: white;
}

td.active.up:before {
  content: "▲";
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.campaign-read__empty-search {
  padding: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.campaign-read__tooltip {
  max-width: 200px;
}

.campaign-read__info {
  max-width: 10px;
  opacity: 0.5;
  position: relative;
  top: -2px;
  margin-left: 2px;
}

.campaign-read__check {
  margin-right: 10px;
}

.campaign-read__social-container-custom__loc {
  display: inline-block;
}

.campaign-read__social-container-custom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.campaign-read__social-container-custom__label-url {
  color: #1e22ff;
}

.campaign-read__table {
  border: none;
}

.campaign-read__table td {
  padding: 10px;
  border-bottom: rgba(0, 0, 0, 0.2);
}

.campaign-read__table tr {
  border-bottom: rgba(0, 0, 0, 0.2);
}

.channel-list {
  list-style: none;
}

.channel-list li {
  background: #efefef;
  display: inline-block;
  margin-right: 10px;
  padding: 4px 10px;
  border-radius: 16px;
  color: #888888;
}

.table__head-ul {
  margin: 0px;
  padding: 0px;
}

.table__head {
  background: #ececec;
  padding: 10px;
  min-width: 200px;
  margin-right: 10px;
}

.Linkify a {
  color: #1157c7;
  vertical-align: top;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign-read__search {
  position: absolute;
  top: 0px;
}

.campaign-read__search-field {
  height: 49px;
  padding: 0px 10px;
  font-size: 12px;
  min-width: 149px;
  border: none;
  border: none;
  width: 177px;
}

.campaign-read__search-field:focus {
  border: none;
}

.campaign-read__search .dashboard__search-close {
  top: 17px;
}

.dashboard__search-close {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 16px;
  border-radius: 10px;
  height: 16px;
  background: #e4e4e4;
  color: white;
  text-align: center;
  line-height: 15px;
  transition: all 0.3s;
  transform: scale(1);
  opacity: 1;
  cursor: pointer;
}

.dashboard__search-close.dashboard__search-close--close {
  opacity: 0;
  transform: scale(0.5);
}

.dashboard__search.dashboard__search--admin {
  margin-bottom: 20px;
}

.dashboard__search.dashboard__search--packages {
  float: none;
  text-align: right;
}

.dashboard__search {
  float: right;
  position: relative;
}

.dashboard__search-field {
  height: 29px;
  border-radius: 18px;
  border: 1px solid #cacaca;
  padding: 0px 10px;
  font-size: 12px;
  min-width: 201px;
}

.campaign-in-preview .campaign-read__activity {
  top: 0px;
}

.campaign-read__wrapper.campaign-read__in-progress {
  width: calc(100% - 287px - 368px);
}

.campaign-review__link {
  max-width: 192px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.input.error-input {
  border: 1px solid red;
}

.close-button img {
  max-width: 20px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.6;
  cursor: pointer;
}

/* .error:empty{
    display: none;
} */

.input--multi {
  width: 100%;
}

.input--multi input.input {
  width: 100%;
}

.input--tiny {
  max-width: 120px;
  padding-left: 10px;
}

.modal__section .grid {
  margin-bottom: 2px;
}

.modal__section select {
  width: 100%;
  margin-bottom: 15px;
}

.modal__section input.input {
  height: 40px;
  line-height: 40px;
}

.modal__section label {
  color: #969696;
  margin-bottom: 13px;
  display: inline-block;
  margin-right: 7px;
}

.modal__section select {
  margin-right: 10px;
}

.form__input-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0px;
}

.menu:empty {
  display: none;
}

.menu {
  background: white;
  text-align: left;
  border: 1px solid #3ccb69;
  border-radius: 3px;
  position: absolute;
  top: 58px;
  max-height: 400px;
  left: 0px;
  right: 0;
  overflow: scroll;
  z-index: 200000000000;
}

.menu > div {
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 13px;
  cursor: pointer;
}

.menu > div:hover {
  background: #f2f7fa;
}

.menu.menu2 .item {
  position: relative;
}

.menu.menu2 .item-right {
  position: absolute;
  right: 10px;
  top: 11px;
  color: rgba(0, 0, 0, 0.3);
}

.menu.menu2 {
  top: 38px;
  left: 0px;
  text-align: left;
}

.menu.menu2 > div {
  padding: 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 13px;
  cursor: pointer;
}

.relative {
  position: relative;
}

.grid {
  display: flex;
}

.grid > div {
  width: 100%;
}

.grid > .input {
  width: auto;
}

.grid__item {
  padding-right: 10px;
  width: 100%;
}

.inp-admin {
  min-width: 200px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 3px;
}

.btn-admin {
  height: 40px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background: #f2f5f7;
  outline: 0;
}

.btn-admin:hover {
  background: #c4e3f3;
}

.left-column {
  width: 350px;
}

.pad-20 {
  padding: 20px;
}

.mar-bot-10 {
  margin-bottom: 10px;
}

.mar-bot-20 {
  margin-bottom: 20px;
}

.pad-10 {
  padding: 10px;
}

.nav__button-group {
  position: absolute;
  top: 15px;
  left: 250px;
}

.nav__button-group > div {
  display: inline-block;
  margin-right: 15px;
}

.cta--members {
  margin-right: 20px;
}

.nav__item-list {
  margin-left: 140px;
  position: relative;
  top: 15px;
}

.nav__item {
  display: inline-block;
  margin-right: 15px;
}

nav a.is-active {
  color: white;
  font-weight: 500;
}

nav a {
  color: #868686;
}

nav a:hover {
  color: white;
}

/* .cta__admin{
    position: absolute;
    top: 15px;
    left: 370px;
}

.cta__ats{
    position: absolute;
    top: 15px;
    left: 250px;
} */

/* .cta__orga{
    position: absolute;
    top: 15px;
    left: 490px;
}

.cta__budget{
    position: absolute;
    top: 15px;
    left: 610px;
}

.cta__invoice{
    position: absolute;
    top: 15px;
    left: 760px;
}

.cta__facebook{
    position: absolute;
    top: 15px;
    left: 805px;
} */

.ml_200 {
  margin-left: 200px;
}

.admin-campaign__images span,
.admin-campaign__images span img {
  vertical-align: top;
}

.admin-campaign__img {
  max-width: 200px;
}

.admin-view__upload {
  margin: 15px 0px;
  margin-right: 15px;
  display: inline-block;
}

.admin-view__bar {
  position: absolute;
  top: 54px;
  left: 0px;
  width: 100%;
  height: 15px;
  background: red;
  z-index: 999999999;
  text-align: center;
  color: white;
}

.campaign-review__image img {
  width: 100%;
}

.campaign-review__image {
  display: inline-block;
  margin-right: 20px;
  max-width: 80px;
}

.campaign-review__headline {
  margin: 0px;
  margin: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  padding-bottom: 6px;
  margin-bottom: 5px;
}

.campaign-review__col {
  width: 50%;
  display: inline-block;
  padding: 20px;
  vertical-align: top;
}

.campaign-review__wrap {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  margin-bottom: 20px;
  border-radius: 5px;
}

.campaign-review__line-ul {
  margin: 0;
  margin-left: 0px;
  padding: 0;
  list-style-position: inside;
  max-width: 200px;
}

.campaign-review__line-invoices {
  margin-top: 10px;
}

.campaign-review__line-ul li {
}

.campaign-review__line > strong {
  display: inline-block;
  width: 154px;
  padding-right: 20px;
}

.campaign-review__line {
  min-height: 33px;
  font-size: 15px;
}

.admin__payment-select {
  border: none;
  background: #bbbbbb;
  color: #848484;
  cursor: pointer;
  font-size: 12px;
  width: 149px;
  line-height: 16px;
  -moz-appearance: none;
  appearance: none;
  -prefix-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 5px 8px;
}

.admin__payment-select-wrap {
  position: relative;
}

.admin__payment-select-wrap:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d7";
  color: black;
  font-size: 11px;
  position: absolute;
  top: 7px;
  right: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.admin__payment-select--PAYED {
  border: none;
  background: #dcf8dc;
  color: #009688;
}

.admin__payment-select--SEND {
  border: none;
  background: #fff6dc;
  color: #ffb40b;
}

.admin__payment-select--REFUSED {
  border: none;
  background: #ffa9a9;
  color: #ff0000;
}

.admin__payment-select--OVERDUE {
  border: none;
  background: #ffa9a9;
  color: #ff0000;
}

.clickable {
  cursor: pointer;
}

.arrow-icon {
  font-size: 7px;
  position: relative;
  top: 0px;
}

.admin__select__mark-as-solved {
  margin-top: 5px;
  display: inline-block;
  background: #eee;
  color: gray;
  cursor: pointer;
  padding: 3px 10px;
  border-radius: 3px;
}

.admin__select__mark-as-solved:hover {
  background: #d9d9d9;
  color: #009688;
}

.admin__select {
  border: none;
  background: #fff6dc;
  color: #ffb40b;
  cursor: pointer;
  font-size: 12px;
  width: 149px;
  line-height: 16px;
  -moz-appearance: none;
  appearance: none;
  -prefix-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 5px 8px;
}

.admin__select.admin__select--read {
  margin-top: 5px;
  width: 100%;
  height: 36px;
  text-align: center;
}

.admin__select-wrap.admin__select-wrap--read:after {
  top: 20px;
}

.admin__select-wrap {
  position: relative;
}

.admin__select-wrap:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d7";
  color: black;
  font-size: 11px;
  position: absolute;
  top: 7px;
  right: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.admin__select--ACTIVE {
  border: none;
  background: #dcf8dc;
  color: #009688;
}

.admin__select--WAITING_FOR_APPROVAL {
  border: none;
  background: #673ab7;
  color: white;
}

.admin__select--ACTION_REQUIRED {
  border: none;
  background: #fab1a0;
  color: #e74c3c;
}

.admin__select--COMMENT_ANSWER_NEEDED {
  border: none;
  background: #fab1a0;
  color: #e74c3c;
}

.admin__select--SCHEDULED {
  border: none;
  background: #667eff;
  color: #3f51b5;
}

.admin__select--REJECTED {
  background: #c7c7c7;
  color: #797979;
}
.admin__select--CANCELLED {
  background: #eebaff;
  color: #ca2efe;
}

.admin__select--STOPPED {
  background: #e4b5b5;
  color: #c34444;
}

.admin__select--FINISHED {
  background: #58beff;
  color: blue;
}

.admin__select--PAUSED {
  background: #dceaff;
  color: #0b4bff;
}

.headline__h1 {
  font-size: 28px;
  color: rgb(55, 81, 126);
  font-weight: 500;
}

.headline__icon {
  position: relative;
  left: -9px;
  top: -3px;
}

.headline__subline {
  font-size: 14px;
  color: rgb(117, 117, 117);
  font-weight: 300;
  margin-top: 12px;
  margin-bottom: 25px;
  line-height: 1.5;
}

.headline__h4 {
  font-size: 28px;
  color: rgb(55, 81, 126);
  font-weight: 600;
}

.campaign-creation__package {
  margin-bottom: 15px;
  padding: 5px 10px;
  transition: background 0.2s;
}

.campaign-creation__package.campaign-creation__package--active {
  background: #e0e0e0;
  padding: 5px 10px;
  border-radius: 3px;
}

.campaign-creation__center-col {
  padding: 0px 20px;
  flex: 1;
}

.campaign-creation__package-name {
  margin: 0;
  font-weight: 600;
  margin-bottom: 10px;
}

.campaign-creation__package-desc {
  color: rgb(117, 117, 117);
}

.campaign-creation__package-item {
  display: flex;
  justify-content: space-between;
}

.campaign-creation__package-item_wrapper {
  display: flex;
}

.campaign-creation__letter {
  width: 35px;
  height: 35px;
  background: #e2d5d5;
  display: inline-block;
  line-height: 35px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  color: rgb(55, 81, 126);
}

.campaign-creation__image-choice {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switcher_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  place-items: baseline;
}

.campaign-creation__ad_preview_wrapper {
  width: 600px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  margin: 0 auto;
  border: 1px lightgray solid;
  flex-direction: column;
}

.campaign-creation__ad_preview_page_left {
  display: flex;
}

.campaign-creation__ad_preview_page_left h4 {
  font-family: "Helvetica Neue";
  font-weight: 500;
  color: #1157c7;
  margin: 0px;
  margin-top: 3px;
  align-self: flex-start;
}

.hashtags {
  color: blue;
}

.campaign-creation__ad_preview_job {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.campaign-creation__ad_preview_page_left img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.campaign-creation__ad_preview_page_left .sponsored {
  height: auto;
  min-width: 100px;
  width: 100px;
  margin-left: -6px;
}

.campaign-creation__ad_preview_page_right img {
  width: 30px;
  height: 30px;
}

.page_name {
  text-align: left;
}

.page_name_wrapper {
  display: flex;
}

.campaign-creation__ad_preview_page_right {
}

.campaign-creation__ad_preview_page_wrapper {
  height: 70px;
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.campaign-creation__ad_preview_image_pic {
  max-width: 600px;
  max-height: 350px;
}

.campaign-creation__ad_preview_image {
  max-width: 100%;
  border-top: 0.5px solid #dedede;
  margin-top: 10px;
  font-family: Helvetica Neue;
  font-weight: 400;
  border-top: 1px lightgray solid;
}

.campaign-creation .rc-switch-checked {
  display: inline-block;
  vertical-align: top;
}

.campaign-creation__headline {
  display: inline-block;
  vertical-align: top;
  margin: 5px 11px;
}

.campaign-creation__ad_navigation {
  display: flex;
  flex-direction: row-reverse;
  max-width: 540px;
  margin: 0 auto;
}

.campaign-creation__ad_navigation div {
  margin-bottom: 10px;
  border: 1px #b3b3b3 solid;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.campaign-creation__sidebar {
  width: 250px;
  height: auto;
  position: fixed;
  right: 40px;
  top: 245px;
}

.campaign-creation__sidebar__card {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 2px rgba(72, 72, 72, 0.1);
  overflow: hidden;
}

.campaign-creation__sidebar .title_wrapper {
  background: #586368;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff5d6;
}

.campaign-creation__sidebar .data_wrapper {
  padding: 20px;
  line-height: 1.9em;
}

.campaign-creation__sidebar .data_wrapper div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.campaign-creation__sidebar .data_wrapper p {
  text-transform: uppercase;
  color: #7a7a7a;
  font-size: 12px;
}

.campaign-creation__sidebar .data_wrapper span {
  text-align: right;
  margin-left: 3px;
  color: #434343;
}

.campaign-creation__sidebar .title_wrapper h4 {
  margin: 0px;
}

.campaign-creation__sidebar_pictures {
  width: 350px;
  height: auto;
  background-color: white;
  position: absolute;
  right: 40px;
  top: 200px;
  border-radius: 3px;
  padding: 20px;
  padding-left: 10px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 15px 2px rgba(72, 72, 72, 0.1);
}

.campaign-creation__ad_preview_cta_wrapper {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  min-height: 100px;
  width: 100%;
  background-color: #e0e6ea;
}

.campaign-creation__ad_preview_cta_wrapper_left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
}

.campaign-creation__ad_preview_cta_wrapper_right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.campaign-creation__ad_preview_cta {
  border: 1px lightgray solid;
  padding: 7px 12px;
  border-radius: 3px;
  min-width: 100px;
  margin-left: 10px;
}

.campaign__create_pictures_picker_sections {
  text-align: left;
}

.campaign__create_pictures_picker_sections li {
  color: #778899;
}

.campaign__create_pictures_picker_sections h3 {
  font-weight: 600;
}

.tag .tipp_info__tool-icon {
  opacity: 0.3;
}

.App .campaign_create__tooltip {
  max-width: 250px;
  line-height: 1.4em;
}

.ad__name {
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #1d2129;
}

.link {
  text-transform: uppercase;
  color: #606772;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.looking__for {
  line-height: 20px;
  font-size: 16px;
  color: #1d2129;
  font-weight: 600;
}

.looking__description {
  color: #606770;
  font-size: 14px;
  line-height: 20px;
}

.campaign-creation__targeting-wrap {
  width: 444px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 20px;
}

.terms-btn {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

.terms-btn.terms-btn-2.checkbox--not-filled {
  border: red solid 2px;
}

.terms-btn-2 {
  margin: 0;
}

.terms-btn .checkbox {
  display: inline;
}

.orga-select {
  -webkit-appearance: button;
  -moz-appearance: button;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); */
  color: #555;
  font-size: inherit;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 10px 14px;
  width: 200px;
  border-radius: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: -7px;
  background: #f2f7fa;
}

.quiz__answer {
  width: 100px;
  height: 100px;
  background: #1e22ff;
  text-align: center;
  line-height: 100px;
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  display: inline-block;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.4s;
}

.quiz__answer.quiz__answer--long {
  width: 100%;
  height: 40px;
  border: 1px solid #e0e0e0;
  background: white;
  text-align: left;
  line-height: 40px;
  padding: 0px 15px;
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  display: block;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.4s;
}

.quiz__answer:active {
  transform: scale(0.9);
}

.nav__orga-list:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d7";
  font-size: 11px;
  position: absolute;
  top: 7px;
  right: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.wrap .wrap__col--active {
  /* background: #dee3ef; */
  color: #8893af;
}

.wrap .wrap__col--deactivate {
  /* background: #f1f1f1; */
  color: #b2bedc;
}

.wrap input[type="date"] {
  margin-left: 10px;
  border-radius: 3px;
  padding: 3px;
  color: #607d8b;
  border: 0;
}

.wrap__collapse-wrap {
  background-color: #adadad14;
  border-left: 3px solid #06baff;
  text-align: left;
}

.wrap__collapse {
  margin-bottom: 20px;
}

.wrap .wrap__col--deactivate input[type="date"] {
  display: none;
}

.wrap__expand {
  color: #b8c3d9;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  opacity: 0.7;
}

.wrap__expand:hover {
  opacity: 1;
}

.wrap {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.wrap__explain {
  white-space: normal;
  line-height: 1.4em;
  margin-left: 25px;
  margin-bottom: 20px;
  opacity: 0.4;
}

.wrap > div {
  margin: 5px;
  width: 100%;
  min-height: 40px;
  border-radius: 7px;
  padding: 0px 15px;
  line-height: 40px;
  white-space: nowrap;
}

.wrap label {
  padding-left: 12px;
}

.preview__change-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.preview__label {
  color: #385997;
  font-weight: 600;
  position: relative;
  top: 3px;
  left: 2px;
}

.preview__light {
  margin-top: 2px;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.2);
}

.preview__icon {
  width: 50px;
  height: 50px;
}

.preview__like {
  width: 140px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.preview__text {
  text-align: left;
  padding: 10px 0px;
  font-size: 15px;
  line-height: 1.3em;
}

.preview {
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin: 10px;
  padding: 15px;
  background: white;
  position: relative;
}

.cta.cta--fix-draft {
  position: absolute;
  top: 0px;
  right: 20px;
}

.preview__container-img {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.preview__container-img .preview__text {
  padding: 15px;
}

.draft .headline__h1 {
  margin: 0px 20px;
  margin-top: 0px;
}

.draft .headline__subline {
  display: none;
}

.draft__headline {
  display: inline-block;
}

.preview__headline {
  font-size: 20px;
  font-weight: 500;
  color: #1d2129;
  font-family: Georgia, serif;
  margin-bottom: 5px;
}

.img-row {
  position: absolute;
  top: 0px;
  left: 20px;
  background: red;
  max-width: 400px;
}

.draft textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.draft input {
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.draft h3 {
  color: #38527c;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 10px;
}

.button-big {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #37dbab;
  color: white;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.grid.grid--preview {
  margin-top: 19px;
  margin-bottom: -120px;
  /*background: red;*/
}

.campaign-creation.campaign-creation--special {
  margin-top: 80px;
}

.column {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  position: relative;
}

.grid__preview.column {
  flex-grow: 2;
  min-width: 700px;
}

.image-roll__search:hover {
  background: gray;
  color: white;
}

.image-roll__search {
  position: absolute;
  top: 17px;
  right: 10px;
  width: 80px;
  height: 24px;
  line-height: 24px;
  color: white;
  background: #f3f3f3;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  color: #8a8a8a;
  font-size: 12px;
}

.image-roll__input-wrap {
  position: relative;
}

.grid__history {
}

.image-roll__item {
  width: 48%;
  object-fit: contain;
  display: inline-block;
  margin-right: 5px;
  background: white;
  margin-bottom: 5px;
  border: 3px solid transparent;
  cursor: pointer;
}

.image-roll__item.image-roll__item--selected {
  border: 3px solid blue;
}

.image-roll__item img {
  width: 100%;
}

.badge-score {
  padding: 4px 5px;
  border-radius: 4px;
}

.badge-score.level_01 {
  background: #eb4f4f;
}

.badge-score.level_02 {
  background: #fe7474;
}

.badge-score.level_03 {
  background: #ffb551;
}

.badge-score.level_04 {
  background: #e4ba49;
}

.badge-score.level_05 {
  background: #ffcb85;
}

.badge-score.level_06 {
  background: #92ca2d;
}

.badge-score.level_07 {
  background: #65d628;
}

/* Customize the label (the container) */

.container_check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container_check input {
  position: absolute;
  opacity: 0;
}

/* Create a custom radio button */

.checkmark_custom {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #cacaca;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.container_check:hover input ~ .checkmark_custom {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.container_check input:checked ~ .checkmark_custom {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark_custom:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container_check input:checked ~ .checkmark_custom:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container_check .checkmark_custom:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.Select-value-label {
  position: relative;
  top: 9px;
}

.explorer h1 {
  margin: 50px;
}

.explorer h2 {
  margin: 20px;
  margin-left: 0px;
  margin-top: 0px;
}

.explorer_wrapper {
  display: flex;
  margin: 50px;
  height: auto;
  align-content: stretch;
  margin-top: 75px;
}

.explorer_left {
  width: 400px;
  border-radius: 3px;
  border: 1px solid lightgray;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 30px;
  padding-top: 20px;
  max-height: 400px;
}

.explorer_right {
  flex: 1;
  margin-left: 20px;
}

.keywords_wrapper {
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.keywords {
  width: 33%;
  height: 400px;
  border: 1px solid lightgray;
  border-radius: 3px;
  background: white;
}

.keywords .header,
.titles_wrapper .header {
  width: 100%;
  height: 50px;
  background: white;
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
}

.keywords .header h4,
.titles_wrapper .header h4 {
  margin: 0px;
  color: #f0ad4e;
  padding: 20px;
  text-transform: uppercase;
}

.keyword {
  display: inline;
  margin: 3px;
  background: #f2f7fa;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 14px;
}

.important_keyword {
  display: inline;
  margin: 3px;
  background: #caffb1;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 14px;
}

.important_keyword_second {
  display: inline;
  margin: 3px;
  background: #b9ecff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 14px;
}

.keywords .frequency {
  font-size: 12px;
  color: #be6464;
}

.keywords_table {
  display: flex;
  flex-flow: row wrap;
}

.explorer nav {
  height: 50px;
  background: white;
  padding-left: 50px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}

.explorer nav img {
  width: 50px;
  margin-right: 10px;
}

.explorer nav h3 {
  color: black;
}

.explorer .logo_wrapper {
  display: flex;
  align-items: center;
}

.explorer .exploring_wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.explorer_right .titles_wrapper {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0.5px 0 2px #e0e6ea;
}

.titles_wrapper .item {
  background: #ffffff82;
  /* padding: 10px; */
  /* padding-left: 20px; */
  /* border: 1px solid #f2f7fa; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: row;
}

.titles_wrapper .item_transparent {
  background: transparent;
  /* padding: 10px; */
  /* padding-left: 20px; */
  /* border: 1px solid #f2f7fa; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: row;
}

.titles_wrapper .all_items {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 35px;
  justify-content: center;
  color: blue;
  cursor: pointer;
  background: white;
  box-shadow: 0.5px 0 1px #e0e6ea;
  background: #ffffff82;
  /* padding: 10px; */
}

.titles_wrapper .clean_titles {
  color: #b3b3b3;
  display: flex;
  text-align: right;
  align-items: center;
  margin-left: 20px;
}

.titles_wrapper .score {
  background: #bce4bff5;
  color: black;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 0.5px 0 2px #e0e6ea;
}

.titles_wrapper .header {
  margin-bottom: 0px;
  display: none;
}

.sentences .sentences_wrapper {
  display: flex;
  padding: 0px 20px;
}

.sentences_wrapper .vertical_header {
  width: 30px;
  background: #e0e6ea;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  color: black;
}

.vertical_header p {
  transform: rotate(-90deg);
  margin: 0px;
  padding: 0px;
}

.sentences_wrapper .text {
  flex: 1;
  background: white;
  border-bottom: 1px solid lightgray;
}

.vertical_header.req {
  background: #e0e6ea;
}

.vertical_header.tasks {
  background: #e0e6ea;
}

.sentences_wrapper.req {
  margin-bottom: 20px;
}

.text .frequency {
  font-size: 12px;
  background: #e0e6ea;
  color: #444;
  padding: 1px 10px;
  border-radius: 3px;
}

.text li {
  padding: 5px;
}

.button_as_a_tag {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  color: rgba(72, 54, 158, 0.89);
  font-weight: 500;
  font: inherit;
}

.button_as_a_tag:hover {
  text-decoration: underline;
}

.explorer_right .section_wrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.section_wrapper .divider {
  height: 1px;
  background: lightgray;
  width: 100px;
}

.section_wrapper .divider.extend {
  flex: 1;
}

.section_wrapper h4 {
  margin: 33px;
  color: #f0ad4e;
  font-weight: 600;
  text-transform: uppercase;
}

.section_wrapper .model {
  padding: 5px 15px;
  margin-right: 10px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 3px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  color: black;
  border: 1px solid #e0e6ea;
  cursor: pointer;
}

.section_wrapper .model.active {
  background: #f0b155;
  color: white;
}

.section_wrapper .model:hover {
  background: #f0b155;
  color: white;
}

.section_wrapper .live_signal {
  height: 5px;
  width: 5px;
  background: #eee;
  margin-right: 10px;
}

.section_wrapper .live_signal.active {
  height: 5px;
  width: 5px;
  background: green;
  margin-right: 10px;
}

.pipeline_wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.pipeline.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 179px;
}

.pipeline_item {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-left: 50px;
}

.pipeline_results {
  margin-left: 30px;
  background: yellow;
}

.keyword-stats__row {
  padding-left: 20px;
}

.keyword-stats__table {
  width: 500px;
  margin-bottom: 10px;
}

.keyword-stats__table thead {
  background: #1434f7;
  color: white;
}

.keyword-stats__table .first {
  width: 200px;
  max-width: 200px;
}

.keyword-stats__table,
.keyword-stats__table td {
  border-collapse: collapse;
}

.keyword-stats__table td {
  border: 1px solid black;
  padding: 5px 10px;
}

.targeting_wrapper {
  display: flex;
}

.targeting_left_panel {
  flex: 1;
  margin-right: 20px;
}

.targeting_left_panel .sub_tabs {
  display: flex;
  justify-content: space-evenly;
  font-size: 12px;
  margin-bottom: 10px;
  max-width: 60%;
}

.targeting_left_panel .btn_wrapper {
  display: flex;
}

.sub_tabs span {
  cursor: pointer;
}

.targeting_right_panel {
  width: 40%;
  height: 600px;
  background: #626d92;
}

.campaign_create__input,
.white .left {
  display: flex;
  width: 55%;
  text-align: left;
  flex-direction: column;
  padding-bottom: 48px;
  border-bottom: 1px solid #efefef;
}

.campaign_create__input .description h4,
.white .description h4,
.channel_wrapper .description h4 {
  font-size: 14px;
  color: #586368;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
}

.campaign_create__input .description p,
.campaign_create__stats .right p,
.white .description p,
.channel_wrapper .description p {
  font-size: 13px;
  line-height: 18px;
  color: #aab2b6;
  margin: 3px;
  margin-left: 0px;
  margin-bottom: 24px;
  max-width: 99%;
}

.campaign_create__stats .right p {
  margin-bottom: 10px;
}

.campaign_create__input .input_wrapper {
  display: flex;
  align-items: center;
}

.input_wrapper .input {
  margin-bottom: 0px;
  text-align: right;
  color: #000000;
  font-size: 24px;
  line-height: 28;
  caret-color: #3c96ff;
}

.input_wrapper div {
  flex: 1;
}

.input_wrapper .euro {
  font-size: 24px;
  color: #586368;
  font-stretch: 0, 6;
  font-weight: 300;
  margin-left: 10px;
}

.white {
  background: white;
  padding: 20px;
  display: flex;
}

.campaign_create__stats {
  flex: 1;
  margin-left: 0px;
  display: flex;
  align-items: start;
  padding-left: 16px;
  min-width: 320px;
  min-height: 75px;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
  align-self: flex-start;
}

.campaign_create__stats img {
  margin-top: 5px;
  width: 32px;
}

.campaign_create__stats .wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #f7f7f7;
  padding-top: 20px;
  padding-left: 20px;
}

.campaign_create__stats .right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 16px;
  text-align: left;
}

.campaign_create__stats .right h4 {
  line-height: 28px;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0.6px;
  font-weight: 300;
  margin: 0px;
}

.campaign_create__stats .tipp_info__tool-icon-inline {
  max-width: 10px;
  opacity: 0.5;
  position: relative;
  top: -8px;
  margin-left: 2px;
}

.campaign_create__stats .tipp_info__tool-icon {
  position: relative;
  top: -18px;
  opacity: 0.3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.campaign-create__toggle-booking {
  position: fixed;
  bottom: 90px;
  background: white;
  color: black;
  width: 200px;
  left: 20px;
  border-radius: 3px;
  overflow: hidden;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(3, 169, 244, 0.2);
}

.campaign-create__toggle-booking__item.campaign-create__toggle-booking__item--active {
  background: #39537c;
  color: white;
}

.campaign-create__toggle-booking__item {
  /* display: inline-block; */
  width: 100%;
}

.campaign-creation .App.campaign-create__pricing-step {
  width: calc(800px - 250px);
}

.channel_wrapper {
  width: 390px;
  /* height: 80px; */
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.App .white .channel_wrapper .description p {
  margin-bottom: 10px;
}

.channel_wrapper.deactivated {
  background: #f7f7f7;
  opacity: 0.6;
}

.white .channel_wrapper .channel_logo {
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel_wrapper .description {
  flex: 1;
  min-height: 48px;
  max-width: 270px;
}

.channel_wrapper .switch {
  width: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.channel_wrapper .switch .upsell {
  font-size: 12px;
  color: #aab2b6;
  letter-spacing: 0.17px;
  margin: 0px;
  margin-top: 0px;
}

.channel_wrapper .channel_logo img {
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
}

.channel_wrapper .rc-switch {
  margin-bottom: 5px;
}

.channel_wrapper .rc-switch-checked {
  border: 1px solid #3c96ff;
  background-color: #3c96ff;
  display: none;
}

.channel_wrapper .channel__budget_fix {
  display: flex;
  align-items: baseline;
  color: #abb3b7;
  margin-top: 0px;
}

.channel_wrapper .channel__budget_fix .editable__value {
  max-width: 35px;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(3, 169, 244, 0.2);
  border: 1px solid #1e22ff;
  outline: 0;
  font-size: 12px;
}

.channel_wrapper .channel__budget_fix .fontawesome__me {
  font-size: 9px;
  margin: 0px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 15px;
  cursor: pointer;
}

.tipp {
  background: #fff5d6;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
  padding-bottom: 24px;
}

.tipp .information {
  display: flex;
}

.tipp .performance {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  padding-right: 20px;
  color: #586368;
  font-weight: 300;
}

.campaign-create__step-back {
  position: fixed;
  left: 98px;
  top: 138px;
  cursor: pointer;
  z-index: 10000000000;
  color: #39537c;
}

.campaign-create__step-back:hover {
  text-decoration: underline;
}

.blinking {
  animation: blinkingText 2s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  50% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 12px 14px 12px;
  border-color: transparent transparent #fff5d6 transparent;
  position: absolute;
  top: 10px;
  left: 10%;
}

.campaign_create__sticky {
  opacity: 1;
  transition: opacity 0.2s;
}

.campaign_create__sticky.campaign_create__sticky--loading {
  pointer-events: none;
  opacity: 0;
}

.campaign_create__stats {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  /* z-index: 1000000000; */
}

.tipp_info__tool-icon {
  max-width: 10px;
  opacity: 0.5;
  position: relative;
  top: -8px;
  margin-left: 2px;
}

.tipp_info__tool-icon-long {
  max-width: 10px;
  opacity: 0.3;
  position: relative;
  top: -16px;
  margin-left: 2px;
}

.tipp .radar {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  margin-right: 24px;
  margin-top: -5px;
  position: relative;
  top: 3px;
  left: -6px;
}

.tipp_info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
}

.tipp_info h2 {
  margin: 0px;
  font-size: 15px;
  font-weight: 500;
  color: #586368;
}

.tipp_info p {
  font-size: 13px;
  color: #000000;
  opacity: 0.4;
  margin: 0px;
  margin-top: 4px;
  max-width: 98%;
  line-height: 18px;
  margin-bottom: 10px;
}

.tipp_info .data {
  margin-top: 20px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.tipp.left_sided .image_wrapper {
  padding-right: 21px;
}

.white.center .right {
  margin-left: 40px;
}

.white.center {
  align-items: center;
}

.create__company-wrapper-button {
  width: 660px;
  margin: 0 auto;
}

.create__company-wrapper {
  padding: 0px 70px;
}

.create__company-heading {
  padding: 0px 70px;
  text-align: left;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.create__company-step {
  color: lightslategrey;
  background: #dee3ed;
  padding: 5px;
  width: 100px;
  text-align: center;
  border-radius: 3px;
  width: 100%;
  max-width: 100px;
}

.create__campaign-heading {
  text-align: left;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.line_divider {
  display: flex;
  width: 100%;
  text-align: left;
  flex-direction: column;
  padding-bottom: -10px;
  border-bottom: 1px solid #efefef;
  background-color: white;
  margin-bottom: 50px;
}

.fontawesome__me:hover {
  color: red;
}

.faq__wrapper {
  width: 800px;
  margin: 0 auto;
  text-align: left;
}

.faq__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.faq__question {
  height: 60px;
  border-top: 1px solid lightgray;
  width: 100%;
  text-align: left;
  padding: 22px 10px;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
}

.faq__question:hover {
  background: aliceblue;
}

.faq__question.isOpen {
  background: white;
  border-bottom: none;
}

.faq__answer {
  min-height: 60px;
  padding: 0px 10px;
  background: white;
  width: 100%;
  text-align: left;
  color: #777777;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  overflow: hidden;
  height: 30px;
}

input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -80px 0 0 80px #43e5f7;
}

input[type="range"]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 24px;
}

input[type="range"]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  border: 0px;
}

/* Special styling for WebKit/Blink */

input[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
  margin-top: -10px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0 0 10px rgba(3, 169, 244, 0.2);
  border: 1px solid #1e22ff;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: -5px;
  /* box-shadow: 0 0 10px rgba(3, 169, 244, 0.2); */
  border: 1px solid #1e22ff;
  box-shadow: 13px 0 0 -5px #f7f7f7, 2px 0 0 -5px #f7f7f7, 3px 0 0 -5px #f7f7f7,
    4px 0 0 -5px #f7f7f7, 5px 0 0 -5px #f7f7f7, 6px 0 0 -5px #f7f7f7, 7px 0 0 -5px #f7f7f7,
    8px 0 0 -5px #f7f7f7, 9px 0 0 -5px #f7f7f7, 10px 0 0 -5px #f7f7f7, 11px 0 0 -5px #f7f7f7,
    12px 0 0 -5px #f7f7f7, 13px 0 0 -5px #f7f7f7, 14px 0 0 -5px #f7f7f7, 15px 0 0 -5px #f7f7f7,
    16px 0 0 -5px #f7f7f7, 17px 0 0 -5px #f7f7f7, 18px 0 0 -5px #f7f7f7, 19px 0 0 -5px #f7f7f7,
    20px 0 0 -5px #f7f7f7, 21px 0 0 -5px #f7f7f7, 22px 0 0 -5px #f7f7f7, 23px 0 0 -5px #f7f7f7,
    24px 0 0 -5px #f7f7f7, 25px 0 0 -5px #f7f7f7, 26px 0 0 -5px #f7f7f7, 27px 0 0 -5px #f7f7f7,
    28px 0 0 -5px #f7f7f7, 29px 0 0 -5px #f7f7f7, 30px 0 0 -5px #f7f7f7, 31px 0 0 -5px #f7f7f7,
    32px 0 0 -5px #f7f7f7, 33px 0 0 -5px #f7f7f7, 34px 0 0 -5px #f7f7f7, 35px 0 0 -5px #f7f7f7,
    36px 0 0 -5px #f7f7f7, 37px 0 0 -5px #f7f7f7, 38px 0 0 -5px #f7f7f7, 39px 0 0 -5px #f7f7f7,
    40px 0 0 -5px #f7f7f7, 41px 0 0 -5px #f7f7f7, 42px 0 0 -5px #f7f7f7, 43px 0 0 -5px #f7f7f7,
    44px 0 0 -5px #f7f7f7, 45px 0 0 -5px #f7f7f7, 46px 0 0 -5px #f7f7f7, 47px 0 0 -5px #f7f7f7,
    48px 0 0 -5px #f7f7f7, 49px 0 0 -5px #f7f7f7, 50px 0 0 -5px #f7f7f7, 51px 0 0 -5px #f7f7f7,
    52px 0 0 -5px #f7f7f7, 53px 0 0 -5px #f7f7f7, 54px 0 0 -5px #f7f7f7, 55px 0 0 -5px #f7f7f7,
    56px 0 0 -5px #f7f7f7, 57px 0 0 -5px #f7f7f7, 58px 0 0 -5px #f7f7f7, 59px 0 0 -5px #f7f7f7,
    60px 0 0 -5px #f7f7f7, 61px 0 0 -5px #f7f7f7, 62px 0 0 -5px #f7f7f7, 63px 0 0 -5px #f7f7f7,
    64px 0 0 -5px #f7f7f7, 65px 0 0 -5px #f7f7f7, 66px 0 0 -5px #f7f7f7, 67px 0 0 -5px #f7f7f7,
    68px 0 0 -5px #f7f7f7, 69px 0 0 -5px #f7f7f7, 70px 0 0 -5px #f7f7f7, 71px 0 0 -5px #f7f7f7,
    72px 0 0 -5px #f7f7f7, 73px 0 0 -5px #f7f7f7, 74px 0 0 -5px #f7f7f7, 75px 0 0 -5px #f7f7f7,
    76px 0 0 -5px #f7f7f7, 77px 0 0 -5px #f7f7f7, 78px 0 0 -5px #f7f7f7, 79px 0 0 -5px #f7f7f7,
    80px 0 0 -5px #f7f7f7, 81px 0 0 -5px #f7f7f7, 82px 0 0 -5px #f7f7f7, 83px 0 0 -5px #f7f7f7,
    84px 0 0 -5px #f7f7f7, 85px 0 0 -5px #f7f7f7, 86px 0 0 -5px #f7f7f7, 87px 0 0 -5px #f7f7f7,
    88px 0 0 -5px #f7f7f7, 89px 0 0 -5px #f7f7f7, 90px 0 0 -5px #f7f7f7, 91px 0 0 -5px #f7f7f7,
    92px 0 0 -5px #f7f7f7, 93px 0 0 -5px #f7f7f7, 94px 0 0 -5px #f7f7f7, 95px 0 0 -5px #f7f7f7,
    96px 0 0 -5px #f7f7f7, 97px 0 0 -5px #f7f7f7, 98px 0 0 -5px #f7f7f7, 99px 0 0 -5px #f7f7f7,
    100px 0 0 -5px #f7f7f7, 101px 0 0 -5px #f7f7f7, 102px 0 0 -5px #f7f7f7, 103px 0 0 -5px #f7f7f7,
    104px 0 0 -5px #f7f7f7, 105px 0 0 -5px #f7f7f7, 106px 0 0 -5px #f7f7f7, 107px 0 0 -5px #f7f7f7,
    108px 0 0 -5px #f7f7f7, 109px 0 0 -5px #f7f7f7, 110px 0 0 -5px #f7f7f7, 111px 0 0 -5px #f7f7f7,
    112px 0 0 -5px #f7f7f7, 113px 0 0 -5px #f7f7f7, 114px 0 0 -5px #f7f7f7, 115px 0 0 -5px #f7f7f7,
    116px 0 0 -5px #f7f7f7, 117px 0 0 -5px #f7f7f7, 118px 0 0 -5px #f7f7f7, 119px 0 0 -5px #f7f7f7,
    120px 0 0 -5px #f7f7f7, 121px 0 0 -5px #f7f7f7, 122px 0 0 -5px #f7f7f7, 123px 0 0 -5px #f7f7f7,
    124px 0 0 -5px #f7f7f7, 125px 0 0 -5px #f7f7f7, 126px 0 0 -5px #f7f7f7, 127px 0 0 -5px #f7f7f7,
    128px 0 0 -5px #f7f7f7, 129px 0 0 -5px #f7f7f7, 130px 0 0 -5px #f7f7f7, 131px 0 0 -5px #f7f7f7,
    132px 0 0 -5px #f7f7f7, 133px 0 0 -5px #f7f7f7, 134px 0 0 -5px #f7f7f7, 135px 0 0 -5px #f7f7f7,
    136px 0 0 -5px #f7f7f7, 137px 0 0 -5px #f7f7f7, 138px 0 0 -5px #f7f7f7, 139px 0 0 -5px #f7f7f7,
    140px 0 0 -5px #f7f7f7, 141px 0 0 -5px #f7f7f7, 142px 0 0 -5px #f7f7f7, 143px 0 0 -5px #f7f7f7,
    144px 0 0 -5px #f7f7f7, 145px 0 0 -5px #f7f7f7, 146px 0 0 -5px #f7f7f7, 147px 0 0 -5px #f7f7f7,
    148px 0 0 -5px #f7f7f7, 149px 0 0 -5px #f7f7f7, 150px 0 0 -5px #f7f7f7, 151px 0 0 -5px #f7f7f7,
    152px 0 0 -5px #f7f7f7, 153px 0 0 -5px #f7f7f7, 154px 0 0 -5px #f7f7f7, 155px 0 0 -5px #f7f7f7,
    156px 0 0 -5px #f7f7f7, 157px 0 0 -5px #f7f7f7, 158px 0 0 -5px #f7f7f7, 159px 0 0 -5px #f7f7f7,
    160px 0 0 -5px #f7f7f7, 161px 0 0 -5px #f7f7f7, 162px 0 0 -5px #f7f7f7, 163px 0 0 -5px #f7f7f7,
    164px 0 0 -5px #f7f7f7, 165px 0 0 -5px #f7f7f7, 166px 0 0 -5px #f7f7f7, 167px 0 0 -5px #f7f7f7,
    168px 0 0 -5px #f7f7f7, 169px 0 0 -5px #f7f7f7, 170px 0 0 -5px #f7f7f7, 171px 0 0 -5px #f7f7f7,
    172px 0 0 -5px #f7f7f7, 173px 0 0 -5px #f7f7f7, 174px 0 0 -5px #f7f7f7, 175px 0 0 -5px #f7f7f7,
    176px 0 0 -5px #f7f7f7, 177px 0 0 -5px #f7f7f7, 178px 0 0 -5px #f7f7f7, 179px 0 0 -5px #f7f7f7,
    180px 0 0 -5px #f7f7f7, 181px 0 0 -5px #f7f7f7, 182px 0 0 -5px #f7f7f7, 183px 0 0 -5px #f7f7f7,
    184px 0 0 -5px #f7f7f7, 185px 0 0 -5px #f7f7f7, 186px 0 0 -5px #f7f7f7, 187px 0 0 -5px #f7f7f7,
    188px 0 0 -5px #f7f7f7, 189px 0 0 -5px #f7f7f7, 190px 0 0 -5px #f7f7f7, 191px 0 0 -5px #f7f7f7,
    192px 0 0 -5px #f7f7f7, 193px 0 0 -5px #f7f7f7, 194px 0 0 -5px #f7f7f7, 195px 0 0 -5px #f7f7f7,
    196px 0 0 -5px #f7f7f7, 197px 0 0 -5px #f7f7f7, 198px 0 0 -5px #f7f7f7, 199px 0 0 -5px #f7f7f7,
    200px 0 0 -5px #f7f7f7, 201px 0 0 -5px #f7f7f7, 202px 0 0 -5px #f7f7f7, 203px 0 0 -5px #f7f7f7,
    204px 0 0 -5px #f7f7f7, 205px 0 0 -5px #f7f7f7, 206px 0 0 -5px #f7f7f7, 207px 0 0 -5px #f7f7f7,
    208px 0 0 -5px #f7f7f7, 209px 0 0 -5px #f7f7f7, 210px 0 0 -5px #f7f7f7, 211px 0 0 -5px #f7f7f7,
    212px 0 0 -5px #f7f7f7, 213px 0 0 -5px #f7f7f7, 214px 0 0 -5px #f7f7f7, 215px 0 0 -5px #f7f7f7,
    216px 0 0 -5px #f7f7f7, 217px 0 0 -5px #f7f7f7, 218px 0 0 -5px #f7f7f7, 219px 0 0 -5px #f7f7f7,
    220px 0 0 -5px #f7f7f7, 221px 0 0 -5px #f7f7f7, 222px 0 0 -5px #f7f7f7, 223px 0 0 -5px #f7f7f7,
    224px 0 0 -5px #f7f7f7, 225px 0 0 -5px #f7f7f7, 226px 0 0 -5px #f7f7f7, 227px 0 0 -5px #f7f7f7,
    228px 0 0 -5px #f7f7f7, 229px 0 0 -5px #f7f7f7, 230px 0 0 -5px #f7f7f7, 231px 0 0 -5px #f7f7f7,
    232px 0 0 -5px #f7f7f7, 233px 0 0 -5px #f7f7f7, 234px 0 0 -5px #f7f7f7, 235px 0 0 -5px #f7f7f7,
    236px 0 0 -5px #f7f7f7, 237px 0 0 -5px #f7f7f7, 238px 0 0 -5px #f7f7f7, 239px 0 0 -5px #f7f7f7,
    240px 0 0 -5px #f7f7f7, 241px 0 0 -5px #f7f7f7, 242px 0 0 -5px #f7f7f7, 243px 0 0 -5px #f7f7f7,
    244px 0 0 -5px #f7f7f7, 245px 0 0 -5px #f7f7f7, 246px 0 0 -5px #f7f7f7, 247px 0 0 -5px #f7f7f7,
    248px 0 0 -5px #f7f7f7, 249px 0 0 -5px #f7f7f7, 250px 0 0 -5px #f7f7f7, 251px 0 0 -5px #f7f7f7,
    252px 0 0 -5px #f7f7f7, 253px 0 0 -5px #f7f7f7, 254px 0 0 -5px #f7f7f7, 255px 0 0 -5px #f7f7f7,
    256px 0 0 -5px #f7f7f7, 257px 0 0 -5px #f7f7f7, 258px 0 0 -5px #f7f7f7, 259px 0 0 -5px #f7f7f7,
    260px 0 0 -5px #f7f7f7, 261px 0 0 -5px #f7f7f7, 262px 0 0 -5px #f7f7f7, 263px 0 0 -5px #f7f7f7,
    264px 0 0 -5px #f7f7f7, 265px 0 0 -5px #f7f7f7, 266px 0 0 -5px #f7f7f7, 267px 0 0 -5px #f7f7f7,
    268px 0 0 -5px #f7f7f7, 269px 0 0 -5px #f7f7f7, 270px 0 0 -5px #f7f7f7, 271px 0 0 -5px #f7f7f7,
    272px 0 0 -5px #f7f7f7, 273px 0 0 -5px #f7f7f7, 274px 0 0 -5px #f7f7f7, 275px 0 0 -5px #f7f7f7,
    276px 0 0 -5px #f7f7f7, 277px 0 0 -5px #f7f7f7, 278px 0 0 -5px #f7f7f7, 279px 0 0 -5px #f7f7f7,
    280px 0 0 -5px #f7f7f7, 281px 0 0 -5px #f7f7f7, 282px 0 0 -5px #f7f7f7, 283px 0 0 -5px #f7f7f7,
    284px 0 0 -5px #f7f7f7, 285px 0 0 -5px #f7f7f7, 286px 0 0 -5px #f7f7f7, 287px 0 0 -5px #f7f7f7,
    288px 0 0 -5px #f7f7f7, 289px 0 0 -5px #f7f7f7, 290px 0 0 -5px #f7f7f7, 291px 0 0 -5px #f7f7f7,
    292px 0 0 -5px #f7f7f7, 293px 0 0 -5px #f7f7f7, 294px 0 0 -5px #f7f7f7, 295px 0 0 -5px #f7f7f7,
    296px 0 0 -5px #f7f7f7, 297px 0 0 -5px #f7f7f7, 298px 0 0 -5px #f7f7f7, 299px 0 0 -5px #f7f7f7,
    300px 0 0 -5px #f7f7f7, 301px 0 0 -5px #f7f7f7, 302px 0 0 -5px #f7f7f7, 303px 0 0 -5px #f7f7f7,
    304px 0 0 -5px #f7f7f7, 305px 0 0 -5px #f7f7f7, 306px 0 0 -5px #f7f7f7, 307px 0 0 -5px #f7f7f7,
    308px 0 0 -5px #f7f7f7, 309px 0 0 -5px #f7f7f7, 310px 0 0 -5px #f7f7f7, 311px 0 0 -5px #f7f7f7,
    312px 0 0 -5px #f7f7f7, 313px 0 0 -5px #f7f7f7, 314px 0 0 -5px #f7f7f7, 315px 0 0 -5px #f7f7f7,
    316px 0 0 -5px #f7f7f7, 317px 0 0 -5px #f7f7f7, 318px 0 0 -5px #f7f7f7, 319px 0 0 -5px #f7f7f7,
    320px 0 0 -5px #f7f7f7, 321px 0 0 -5px #f7f7f7, 322px 0 0 -5px #f7f7f7, 323px 0 0 -5px #f7f7f7,
    324px 0 0 -5px #f7f7f7, 325px 0 0 -5px #f7f7f7, 326px 0 0 -5px #f7f7f7, 327px 0 0 -5px #f7f7f7,
    328px 0 0 -5px #f7f7f7, 329px 0 0 -5px #f7f7f7, 330px 0 0 -5px #f7f7f7, 331px 0 0 -5px #f7f7f7,
    332px 0 0 -5px #f7f7f7, 333px 0 0 -5px #f7f7f7, 334px 0 0 -5px #f7f7f7, 335px 0 0 -5px #f7f7f7,
    336px 0 0 -5px #f7f7f7, 337px 0 0 -5px #f7f7f7, 338px 0 0 -5px #f7f7f7, 339px 0 0 -5px #f7f7f7,
    340px 0 0 -5px #f7f7f7, 341px 0 0 -5px #f7f7f7, 342px 0 0 -5px #f7f7f7, 343px 0 0 -5px #f7f7f7,
    344px 0 0 -5px #f7f7f7, 345px 0 0 -5px #f7f7f7, 346px 0 0 -5px #f7f7f7, 347px 0 0 -5px #f7f7f7,
    348px 0 0 -5px #f7f7f7, 349px 0 0 -5px #f7f7f7, 350px 0 0 -5px #f7f7f7, 351px 0 0 -5px #f7f7f7,
    352px 0 0 -5px #f7f7f7, 353px 0 0 -5px #f7f7f7, 354px 0 0 -5px #f7f7f7, 355px 0 0 -5px #f7f7f7,
    356px 0 0 -5px #f7f7f7, 357px 0 0 -5px #f7f7f7, 358px 0 0 -5px #f7f7f7, 359px 0 0 -5px #f7f7f7,
    360px 0 0 -5px #f7f7f7, 361px 0 0 -5px #f7f7f7, 362px 0 0 -5px #f7f7f7, 363px 0 0 -5px #f7f7f7,
    364px 0 0 -5px #f7f7f7, 365px 0 0 -5px #f7f7f7, 366px 0 0 -5px #f7f7f7, 367px 0 0 -5px #f7f7f7,
    368px 0 0 -5px #f7f7f7, 369px 0 0 -5px #f7f7f7, 370px 0 0 -5px #f7f7f7, 371px 0 0 -5px #f7f7f7,
    372px 0 0 -5px #f7f7f7, 373px 0 0 -5px #f7f7f7, 374px 0 0 -5px #f7f7f7, 375px 0 0 -5px #f7f7f7,
    376px 0 0 -5px #f7f7f7, 377px 0 0 -5px #f7f7f7, 378px 0 0 -5px #f7f7f7, 379px 0 0 -5px #f7f7f7,
    380px 0 0 -5px #f7f7f7, 381px 0 0 -5px #f7f7f7, 382px 0 0 -5px #f7f7f7, 383px 0 0 -5px #f7f7f7,
    384px 0 0 -5px #f7f7f7, 385px 0 0 -5px #f7f7f7, 386px 0 0 -5px #f7f7f7, 387px 0 0 -5px #f7f7f7,
    388px 0 0 -5px #f7f7f7, 389px 0 0 -5px #f7f7f7, 390px 0 0 -5px #f7f7f7, 391px 0 0 -5px #f7f7f7,
    392px 0 0 -5px #f7f7f7, 393px 0 0 -5px #f7f7f7, 394px 0 0 -5px #f7f7f7, 395px 0 0 -5px #f7f7f7,
    396px 0 0 -5px #f7f7f7, 397px 0 0 -5px #f7f7f7, 398px 0 0 -5px #f7f7f7, 399px 0 0 -5px #f7f7f7,
    400px 0 0 -5px #f7f7f7, 401px 0 0 -5px #f7f7f7, 402px 0 0 -5px #f7f7f7, 403px 0 0 -5px #f7f7f7,
    404px 0 0 -5px #f7f7f7, 405px 0 0 -5px #f7f7f7, 406px 0 0 -5px #f7f7f7, 407px 0 0 -5px #f7f7f7,
    408px 0 0 -5px #f7f7f7, 409px 0 0 -5px #f7f7f7, 410px 0 0 -5px #f7f7f7, 411px 0 0 -5px #f7f7f7,
    412px 0 0 -5px #f7f7f7, 413px 0 0 -5px #f7f7f7, 414px 0 0 -5px #f7f7f7, 415px 0 0 -5px #f7f7f7,
    416px 0 0 -5px #f7f7f7, 417px 0 0 -5px #f7f7f7, 418px 0 0 -5px #f7f7f7, 419px 0 0 -5px #f7f7f7,
    420px 0 0 -5px #f7f7f7, 421px 0 0 -5px #f7f7f7, 422px 0 0 -5px #f7f7f7, 423px 0 0 -5px #f7f7f7,
    424px 0 0 -5px #f7f7f7, 425px 0 0 -5px #f7f7f7, 426px 0 0 -5px #f7f7f7, 427px 0 0 -5px #f7f7f7,
    428px 0 0 -5px #f7f7f7, 429px 0 0 -5px #f7f7f7, 430px 0 0 -5px #f7f7f7, 431px 0 0 -5px #f7f7f7,
    432px 0 0 -5px #f7f7f7, 433px 0 0 -5px #f7f7f7, 434px 0 0 -5px #f7f7f7, 435px 0 0 -5px #f7f7f7,
    436px 0 0 -5px #f7f7f7, 437px 0 0 -5px #f7f7f7, 438px 0 0 -5px #f7f7f7, 439px 0 0 -5px #f7f7f7,
    440px 0 0 -5px #f7f7f7, 441px 0 0 -5px #f7f7f7, 442px 0 0 -5px #f7f7f7, 443px 0 0 -5px #f7f7f7,
    444px 0 0 -5px #f7f7f7, 445px 0 0 -5px #f7f7f7, 446px 0 0 -5px #f7f7f7, 447px 0 0 -5px #f7f7f7,
    448px 0 0 -5px #f7f7f7, 449px 0 0 -5px #f7f7f7, 450px 0 0 -5px #f7f7f7, 451px 0 0 -5px #f7f7f7,
    452px 0 0 -5px #f7f7f7, 453px 0 0 -5px #f7f7f7, 454px 0 0 -5px #f7f7f7, 455px 0 0 -5px #f7f7f7,
    456px 0 0 -5px #f7f7f7, 457px 0 0 -5px #f7f7f7, 458px 0 0 -5px #f7f7f7, 459px 0 0 -5px #f7f7f7,
    460px 0 0 -5px #f7f7f7, 461px 0 0 -5px #f7f7f7, 462px 0 0 -5px #f7f7f7, 463px 0 0 -5px #f7f7f7,
    464px 0 0 -5px #f7f7f7, 465px 0 0 -5px #f7f7f7, 466px 0 0 -5px #f7f7f7, 467px 0 0 -5px #f7f7f7,
    468px 0 0 -5px #f7f7f7, 469px 0 0 -5px #f7f7f7, 470px 0 0 -5px #f7f7f7, 471px 0 0 -5px #f7f7f7,
    472px 0 0 -5px #f7f7f7, 473px 0 0 -5px #f7f7f7, 474px 0 0 -5px #f7f7f7, 475px 0 0 -5px #f7f7f7,
    476px 0 0 -5px #f7f7f7, 477px 0 0 -5px #f7f7f7, 478px 0 0 -5px #f7f7f7, 479px 0 0 -5px #f7f7f7,
    480px 0 0 -5px #f7f7f7, 481px 0 0 -5px #f7f7f7, 482px 0 0 -5px #f7f7f7, 483px 0 0 -5px #f7f7f7,
    484px 0 0 -5px #f7f7f7, 485px 0 0 -5px #f7f7f7, 486px 0 0 -5px #f7f7f7, 487px 0 0 -5px #f7f7f7,
    488px 0 0 -5px #f7f7f7, 489px 0 0 -5px #f7f7f7, 490px 0 0 -5px #f7f7f7, 491px 0 0 -5px #f7f7f7,
    492px 0 0 -5px #f7f7f7, 493px 0 0 -5px #f7f7f7, 494px 0 0 -5px #f7f7f7, 495px 0 0 -5px #f7f7f7,
    496px 0 0 -5px #f7f7f7, 497px 0 0 -5px #f7f7f7, 498px 0 0 -5px #f7f7f7, 499px 0 0 -5px #f7f7f7,
    500px 0 0 -5px #f7f7f7, 501px 0 0 -5px #f7f7f7, 502px 0 0 -5px #f7f7f7, 503px 0 0 -5px #f7f7f7,
    504px 0 0 -5px #f7f7f7, 505px 0 0 -5px #f7f7f7, 506px 0 0 -5px #f7f7f7, 507px 0 0 -5px #f7f7f7,
    508px 0 0 -5px #f7f7f7, 509px 0 0 -5px #f7f7f7, 510px 0 0 -5px #f7f7f7, 511px 0 0 -5px #f7f7f7,
    512px 0 0 -5px #f7f7f7, 513px 0 0 -5px #f7f7f7, 514px 0 0 -5px #f7f7f7, 515px 0 0 -5px #f7f7f7,
    516px 0 0 -5px #f7f7f7, 517px 0 0 -5px #f7f7f7, 518px 0 0 -5px #f7f7f7, 519px 0 0 -5px #f7f7f7,
    520px 0 0 -5px #f7f7f7, 521px 0 0 -5px #f7f7f7, 522px 0 0 -5px #f7f7f7, 523px 0 0 -5px #f7f7f7,
    524px 0 0 -5px #f7f7f7, 525px 0 0 -5px #f7f7f7, 526px 0 0 -5px #f7f7f7, 527px 0 0 -5px #f7f7f7,
    528px 0 0 -5px #f7f7f7, 529px 0 0 -5px #f7f7f7, 530px 0 0 -5px #f7f7f7, 531px 0 0 -5px #f7f7f7,
    532px 0 0 -5px #f7f7f7, 533px 0 0 -5px #f7f7f7, 534px 0 0 -5px #f7f7f7, 535px 0 0 -5px #f7f7f7,
    536px 0 0 -5px #f7f7f7, 537px 0 0 -5px #f7f7f7, 538px 0 0 -5px #f7f7f7, 539px 0 0 -5px #f7f7f7,
    540px 0 0 -5px #f7f7f7, 541px 0 0 -5px #f7f7f7, 542px 0 0 -5px #f7f7f7, 543px 0 0 -5px #f7f7f7,
    544px 0 0 -5px #f7f7f7, 545px 0 0 -5px #f7f7f7, 546px 0 0 -5px #f7f7f7, 547px 0 0 -5px #f7f7f7,
    548px 0 0 -5px #f7f7f7, 549px 0 0 -5px #f7f7f7, 550px 0 0 -5px #f7f7f7, 551px 0 0 -5px #f7f7f7,
    552px 0 0 -5px #f7f7f7, 553px 0 0 -5px #f7f7f7, 554px 0 0 -5px #f7f7f7, 555px 0 0 -5px #f7f7f7,
    556px 0 0 -5px #f7f7f7, 557px 0 0 -5px #f7f7f7, 558px 0 0 -5px #f7f7f7, 559px 0 0 -5px #f7f7f7,
    560px 0 0 -5px #f7f7f7, 561px 0 0 -5px #f7f7f7, 562px 0 0 -5px #f7f7f7, 563px 0 0 -5px #f7f7f7,
    564px 0 0 -5px #f7f7f7, 565px 0 0 -5px #f7f7f7, 566px 0 0 -5px #f7f7f7, 567px 0 0 -5px #f7f7f7,
    568px 0 0 -5px #f7f7f7, 569px 0 0 -5px #f7f7f7, 570px 0 0 -5px #f7f7f7, 571px 0 0 -5px #f7f7f7,
    572px 0 0 -5px #f7f7f7, 573px 0 0 -5px #f7f7f7, 574px 0 0 -5px #f7f7f7, 575px 0 0 -5px #f7f7f7,
    576px 0 0 -5px #f7f7f7, 577px 0 0 -5px #f7f7f7, 578px 0 0 -5px #f7f7f7, 579px 0 0 -5px #f7f7f7,
    580px 0 0 -5px #f7f7f7, 581px 0 0 -5px #f7f7f7, 582px 0 0 -5px #f7f7f7, 583px 0 0 -5px #f7f7f7,
    584px 0 0 -5px #f7f7f7, 585px 0 0 -5px #f7f7f7, 586px 0 0 -5px #f7f7f7, 587px 0 0 -5px #f7f7f7,
    588px 0 0 -5px #f7f7f7, 589px 0 0 -5px #f7f7f7, 590px 0 0 -5px #f7f7f7, 591px 0 0 -5px #f7f7f7,
    592px 0 0 -5px #f7f7f7, 593px 0 0 -5px #f7f7f7, 594px 0 0 -5px #f7f7f7, 595px 0 0 -5px #f7f7f7,
    596px 0 0 -5px #f7f7f7, 597px 0 0 -5px #f7f7f7, 598px 0 0 -5px #f7f7f7, 599px 0 0 -5px #f7f7f7,
    600px 0 0 -5px #f7f7f7, 601px 0 0 -5px #f7f7f7, 602px 0 0 -5px #f7f7f7, 603px 0 0 -5px #f7f7f7,
    604px 0 0 -5px #f7f7f7, 605px 0 0 -5px #f7f7f7, 606px 0 0 -5px #f7f7f7, 607px 0 0 -5px #f7f7f7,
    608px 0 0 -5px #f7f7f7, 609px 0 0 -5px #f7f7f7, 610px 0 0 -5px #f7f7f7, 611px 0 0 -5px #f7f7f7,
    612px 0 0 -5px #f7f7f7, 613px 0 0 -5px #f7f7f7, 614px 0 0 -5px #f7f7f7, 615px 0 0 -5px #f7f7f7,
    616px 0 0 -5px #f7f7f7, 617px 0 0 -5px #f7f7f7, 618px 0 0 -5px #f7f7f7, 619px 0 0 -5px #f7f7f7,
    620px 0 0 -5px #f7f7f7, 621px 0 0 -5px #f7f7f7, 622px 0 0 -5px #f7f7f7, 623px 0 0 -5px #f7f7f7,
    624px 0 0 -5px #f7f7f7, 625px 0 0 -5px #f7f7f7, 626px 0 0 -5px #f7f7f7, 627px 0 0 -5px #f7f7f7,
    628px 0 0 -5px #f7f7f7, 629px 0 0 -5px #f7f7f7, 630px 0 0 -5px #f7f7f7, 631px 0 0 -5px #f7f7f7,
    632px 0 0 -5px #f7f7f7, 633px 0 0 -5px #f7f7f7, 634px 0 0 -5px #f7f7f7, 635px 0 0 -5px #f7f7f7,
    636px 0 0 -5px #f7f7f7, 637px 0 0 -5px #f7f7f7, 638px 0 0 -5px #f7f7f7, 639px 0 0 -5px #f7f7f7,
    640px 0 0 -5px #f7f7f7, 641px 0 0 -5px #f7f7f7, 642px 0 0 -5px #f7f7f7, 643px 0 0 -5px #f7f7f7,
    644px 0 0 -5px #f7f7f7, 645px 0 0 -5px #f7f7f7, 646px 0 0 -5px #f7f7f7, 647px 0 0 -5px #f7f7f7,
    648px 0 0 -5px #f7f7f7, 649px 0 0 -5px #f7f7f7, 650px 0 0 -5px #f7f7f7, 651px 0 0 -5px #f7f7f7,
    652px 0 0 -5px #f7f7f7, 653px 0 0 -5px #f7f7f7, 654px 0 0 -5px #f7f7f7, 655px 0 0 -5px #f7f7f7,
    656px 0 0 -5px #f7f7f7, 657px 0 0 -5px #f7f7f7, 658px 0 0 -5px #f7f7f7, 659px 0 0 -5px #f7f7f7,
    660px 0 0 -5px #f7f7f7, 661px 0 0 -5px #f7f7f7, 662px 0 0 -5px #f7f7f7, 663px 0 0 -5px #f7f7f7,
    664px 0 0 -5px #f7f7f7, 665px 0 0 -5px #f7f7f7, 666px 0 0 -5px #f7f7f7, 667px 0 0 -5px #f7f7f7,
    668px 0 0 -5px #f7f7f7, 669px 0 0 -5px #f7f7f7, 670px 0 0 -5px #f7f7f7, 671px 0 0 -5px #f7f7f7,
    672px 0 0 -5px #f7f7f7, 673px 0 0 -5px #f7f7f7, 674px 0 0 -5px #f7f7f7, 675px 0 0 -5px #f7f7f7,
    676px 0 0 -5px #f7f7f7, 677px 0 0 -5px #f7f7f7, 678px 0 0 -5px #f7f7f7, 679px 0 0 -5px #f7f7f7,
    680px 0 0 -5px #f7f7f7, 681px 0 0 -5px #f7f7f7, 682px 0 0 -5px #f7f7f7, 683px 0 0 -5px #f7f7f7,
    684px 0 0 -5px #f7f7f7, 685px 0 0 -5px #f7f7f7, 686px 0 0 -5px #f7f7f7, 687px 0 0 -5px #f7f7f7,
    688px 0 0 -5px #f7f7f7, 689px 0 0 -5px #f7f7f7, 690px 0 0 -5px #f7f7f7, 691px 0 0 -5px #f7f7f7,
    692px 0 0 -5px #f7f7f7, 693px 0 0 -5px #f7f7f7, 694px 0 0 -5px #f7f7f7, 695px 0 0 -5px #f7f7f7,
    696px 0 0 -5px #f7f7f7, 697px 0 0 -5px #f7f7f7, 698px 0 0 -5px #f7f7f7, 699px 0 0 -5px #f7f7f7,
    700px 0 0 -5px #f7f7f7, 701px 0 0 -5px #f7f7f7, 702px 0 0 -5px #f7f7f7, 703px 0 0 -5px #f7f7f7,
    704px 0 0 -5px #f7f7f7, 705px 0 0 -5px #f7f7f7, 706px 0 0 -5px #f7f7f7, 707px 0 0 -5px #f7f7f7,
    708px 0 0 -5px #f7f7f7, 709px 0 0 -5px #f7f7f7, 710px 0 0 -5px #f7f7f7, 711px 0 0 -5px #f7f7f7,
    712px 0 0 -5px #f7f7f7, 713px 0 0 -5px #f7f7f7, 714px 0 0 -5px #f7f7f7, 715px 0 0 -5px #f7f7f7,
    716px 0 0 -5px #f7f7f7, 717px 0 0 -5px #f7f7f7, 718px 0 0 -5px #f7f7f7, 719px 0 0 -5px #f7f7f7,
    720px 0 0 -5px #f7f7f7, 721px 0 0 -5px #f7f7f7, 722px 0 0 -5px #f7f7f7, 723px 0 0 -5px #f7f7f7,
    724px 0 0 -5px #f7f7f7, 725px 0 0 -5px #f7f7f7, 726px 0 0 -5px #f7f7f7, 727px 0 0 -5px #f7f7f7,
    728px 0 0 -5px #f7f7f7, 729px 0 0 -5px #f7f7f7, 730px 0 0 -5px #f7f7f7, 731px 0 0 -5px #f7f7f7,
    732px 0 0 -5px #f7f7f7, 733px 0 0 -5px #f7f7f7, 734px 0 0 -5px #f7f7f7, 735px 0 0 -5px #f7f7f7,
    736px 0 0 -5px #f7f7f7, 737px 0 0 -5px #f7f7f7, 738px 0 0 -5px #f7f7f7, 739px 0 0 -5px #f7f7f7,
    740px 0 0 -5px #f7f7f7, 741px 0 0 -5px #f7f7f7, 742px 0 0 -5px #f7f7f7, 743px 0 0 -5px #f7f7f7,
    744px 0 0 -5px #f7f7f7, 745px 0 0 -5px #f7f7f7, 746px 0 0 -5px #f7f7f7, 747px 0 0 -5px #f7f7f7,
    748px 0 0 -5px #f7f7f7, 749px 0 0 -5px #f7f7f7, 750px 0 0 -5px #f7f7f7, 751px 0 0 -5px #f7f7f7,
    752px 0 0 -5px #f7f7f7, 753px 0 0 -5px #f7f7f7, 754px 0 0 -5px #f7f7f7, 755px 0 0 -5px #f7f7f7,
    756px 0 0 -5px #f7f7f7, 757px 0 0 -5px #f7f7f7, 758px 0 0 -5px #f7f7f7, 759px 0 0 -5px #f7f7f7,
    760px 0 0 -5px #f7f7f7, 761px 0 0 -5px #f7f7f7, 762px 0 0 -5px #f7f7f7, 763px 0 0 -5px #f7f7f7,
    764px 0 0 -5px #f7f7f7, 765px 0 0 -5px #f7f7f7, 766px 0 0 -5px #f7f7f7, 767px 0 0 -5px #f7f7f7,
    768px 0 0 -5px #f7f7f7, 769px 0 0 -5px #f7f7f7, 770px 0 0 -5px #f7f7f7, 771px 0 0 -5px #f7f7f7,
    772px 0 0 -5px #f7f7f7, 773px 0 0 -5px #f7f7f7, 774px 0 0 -5px #f7f7f7, 775px 0 0 -5px #f7f7f7,
    776px 0 0 -5px #f7f7f7, 777px 0 0 -5px #f7f7f7, 778px 0 0 -5px #f7f7f7, 779px 0 0 -5px #f7f7f7,
    780px 0 0 -5px #f7f7f7, 781px 0 0 -5px #f7f7f7, 782px 0 0 -5px #f7f7f7, 783px 0 0 -5px #f7f7f7,
    784px 0 0 -5px #f7f7f7, 785px 0 0 -5px #f7f7f7, 786px 0 0 -5px #f7f7f7, 787px 0 0 -5px #f7f7f7,
    788px 0 0 -5px #f7f7f7, 789px 0 0 -5px #f7f7f7, 790px 0 0 -5px #f7f7f7, 791px 0 0 -5px #f7f7f7,
    792px 0 0 -5px #f7f7f7, 793px 0 0 -5px #f7f7f7, 794px 0 0 -5px #f7f7f7, 795px 0 0 -5px #f7f7f7,
    796px 0 0 -5px #f7f7f7, 797px 0 0 -5px #f7f7f7, 798px 0 0 -5px #f7f7f7, 799px 0 0 -5px #f7f7f7,
    800px 0 0 -5px #f7f7f7, 801px 0 0 -5px #f7f7f7, 802px 0 0 -5px #f7f7f7, 803px 0 0 -5px #f7f7f7,
    804px 0 0 -5px #f7f7f7, 805px 0 0 -5px #f7f7f7, 806px 0 0 -5px #f7f7f7, 807px 0 0 -5px #f7f7f7,
    808px 0 0 -5px #f7f7f7, 809px 0 0 -5px #f7f7f7, 810px 0 0 -5px #f7f7f7, 811px 0 0 -5px #f7f7f7,
    812px 0 0 -5px #f7f7f7, 813px 0 0 -5px #f7f7f7, 814px 0 0 -5px #f7f7f7, 815px 0 0 -5px #f7f7f7,
    816px 0 0 -5px #f7f7f7, 817px 0 0 -5px #f7f7f7, 818px 0 0 -5px #f7f7f7, 819px 0 0 -5px #f7f7f7,
    820px 0 0 -5px #f7f7f7, 821px 0 0 -5px #f7f7f7, 822px 0 0 -5px #f7f7f7, 823px 0 0 -5px #f7f7f7,
    824px 0 0 -5px #f7f7f7, 825px 0 0 -5px #f7f7f7, 826px 0 0 -5px #f7f7f7, 827px 0 0 -5px #f7f7f7,
    828px 0 0 -5px #f7f7f7, 829px 0 0 -5px #f7f7f7, 830px 0 0 -5px #f7f7f7, 831px 0 0 -5px #f7f7f7,
    832px 0 0 -5px #f7f7f7, 833px 0 0 -5px #f7f7f7, 834px 0 0 -5px #f7f7f7, 835px 0 0 -5px #f7f7f7,
    836px 0 0 -5px #f7f7f7, 837px 0 0 -5px #f7f7f7, 838px 0 0 -5px #f7f7f7, 839px 0 0 -5px #f7f7f7,
    840px 0 0 -5px #f7f7f7, 841px 0 0 -5px #f7f7f7, 842px 0 0 -5px #f7f7f7, 843px 0 0 -5px #f7f7f7,
    844px 0 0 -5px #f7f7f7, 845px 0 0 -5px #f7f7f7, 846px 0 0 -5px #f7f7f7, 847px 0 0 -5px #f7f7f7,
    848px 0 0 -5px #f7f7f7, 849px 0 0 -5px #f7f7f7, 850px 0 0 -5px #f7f7f7, 851px 0 0 -5px #f7f7f7,
    852px 0 0 -5px #f7f7f7, 853px 0 0 -5px #f7f7f7, 854px 0 0 -5px #f7f7f7, 855px 0 0 -5px #f7f7f7,
    856px 0 0 -5px #f7f7f7, 857px 0 0 -5px #f7f7f7, 858px 0 0 -5px #f7f7f7, 859px 0 0 -5px #f7f7f7,
    860px 0 0 -5px #f7f7f7, 861px 0 0 -5px #f7f7f7, 862px 0 0 -5px #f7f7f7, 863px 0 0 -5px #f7f7f7,
    864px 0 0 -5px #f7f7f7, 865px 0 0 -5px #f7f7f7, 866px 0 0 -5px #f7f7f7, 867px 0 0 -5px #f7f7f7,
    868px 0 0 -5px #f7f7f7, 869px 0 0 -5px #f7f7f7, 870px 0 0 -5px #f7f7f7, 871px 0 0 -5px #f7f7f7,
    872px 0 0 -5px #f7f7f7, 873px 0 0 -5px #f7f7f7, 874px 0 0 -5px #f7f7f7, 875px 0 0 -5px #f7f7f7,
    876px 0 0 -5px #f7f7f7, 877px 0 0 -5px #f7f7f7, 878px 0 0 -5px #f7f7f7, 879px 0 0 -5px #f7f7f7,
    880px 0 0 -5px #f7f7f7, 881px 0 0 -5px #f7f7f7, 882px 0 0 -5px #f7f7f7, 883px 0 0 -5px #f7f7f7,
    884px 0 0 -5px #f7f7f7, 885px 0 0 -5px #f7f7f7, 886px 0 0 -5px #f7f7f7, 887px 0 0 -5px #f7f7f7,
    888px 0 0 -5px #f7f7f7, 889px 0 0 -5px #f7f7f7, 890px 0 0 -5px #f7f7f7, 891px 0 0 -5px #f7f7f7,
    892px 0 0 -5px #f7f7f7, 893px 0 0 -5px #f7f7f7, 894px 0 0 -5px #f7f7f7, 895px 0 0 -5px #f7f7f7,
    896px 0 0 -5px #f7f7f7, 897px 0 0 -5px #f7f7f7, 898px 0 0 -5px #f7f7f7, 899px 0 0 -5px #f7f7f7,
    900px 0 0 -5px #f7f7f7, 901px 0 0 -5px #f7f7f7, 902px 0 0 -5px #f7f7f7, 903px 0 0 -5px #f7f7f7,
    904px 0 0 -5px #f7f7f7, 905px 0 0 -5px #f7f7f7, 906px 0 0 -5px #f7f7f7, 907px 0 0 -5px #f7f7f7,
    908px 0 0 -5px #f7f7f7, 909px 0 0 -5px #f7f7f7, 910px 0 0 -5px #f7f7f7, 911px 0 0 -5px #f7f7f7,
    912px 0 0 -5px #f7f7f7, 913px 0 0 -5px #f7f7f7, 914px 0 0 -5px #f7f7f7, 915px 0 0 -5px #f7f7f7,
    916px 0 0 -5px #f7f7f7, 917px 0 0 -5px #f7f7f7, 918px 0 0 -5px #f7f7f7, 919px 0 0 -5px #f7f7f7,
    920px 0 0 -5px #f7f7f7, 921px 0 0 -5px #f7f7f7, 922px 0 0 -5px #f7f7f7, 923px 0 0 -5px #f7f7f7,
    924px 0 0 -5px #f7f7f7, 925px 0 0 -5px #f7f7f7, 926px 0 0 -5px #f7f7f7, 927px 0 0 -5px #f7f7f7,
    928px 0 0 -5px #f7f7f7, 929px 0 0 -5px #f7f7f7, 930px 0 0 -5px #f7f7f7, 931px 0 0 -5px #f7f7f7,
    932px 0 0 -5px #f7f7f7, 933px 0 0 -5px #f7f7f7, 934px 0 0 -5px #f7f7f7, 935px 0 0 -5px #f7f7f7,
    936px 0 0 -5px #f7f7f7, 937px 0 0 -5px #f7f7f7, 938px 0 0 -5px #f7f7f7, 939px 0 0 -5px #f7f7f7,
    940px 0 0 -5px #f7f7f7, 941px 0 0 -5px #f7f7f7, 942px 0 0 -5px #f7f7f7, 943px 0 0 -5px #f7f7f7,
    944px 0 0 -5px #f7f7f7, 945px 0 0 -5px #f7f7f7, 946px 0 0 -5px #f7f7f7, 947px 0 0 -5px #f7f7f7,
    948px 0 0 -5px #f7f7f7, 949px 0 0 -5px #f7f7f7, 950px 0 0 -5px #f7f7f7, 951px 0 0 -5px #f7f7f7,
    952px 0 0 -5px #f7f7f7, 953px 0 0 -5px #f7f7f7, 954px 0 0 -5px #f7f7f7, 955px 0 0 -5px #f7f7f7,
    956px 0 0 -5px #f7f7f7, 957px 0 0 -5px #f7f7f7, 958px 0 0 -5px #f7f7f7, 959px 0 0 -5px #f7f7f7,
    960px 0 0 -5px #f7f7f7, 961px 0 0 -5px #f7f7f7, 962px 0 0 -5px #f7f7f7, 963px 0 0 -5px #f7f7f7,
    964px 0 0 -5px #f7f7f7, 965px 0 0 -5px #f7f7f7, 966px 0 0 -5px #f7f7f7, 967px 0 0 -5px #f7f7f7,
    968px 0 0 -5px #f7f7f7, 969px 0 0 -5px #f7f7f7, 970px 0 0 -5px #f7f7f7, 971px 0 0 -5px #f7f7f7,
    972px 0 0 -5px #f7f7f7, 973px 0 0 -5px #f7f7f7, 974px 0 0 -5px #f7f7f7, 975px 0 0 -5px #f7f7f7,
    976px 0 0 -5px #f7f7f7, 977px 0 0 -5px #f7f7f7, 978px 0 0 -5px #f7f7f7, 979px 0 0 -5px #f7f7f7,
    980px 0 0 -5px #f7f7f7, 981px 0 0 -5px #f7f7f7, 982px 0 0 -5px #f7f7f7, 983px 0 0 -5px #f7f7f7,
    984px 0 0 -5px #f7f7f7, 985px 0 0 -5px #f7f7f7, 986px 0 0 -5px #f7f7f7, 987px 0 0 -5px #f7f7f7,
    988px 0 0 -5px #f7f7f7, 989px 0 0 -5px #f7f7f7, 990px 0 0 -5px #f7f7f7, 991px 0 0 -5px #f7f7f7,
    992px 0 0 -5px #f7f7f7, 993px 0 0 -5px #f7f7f7, 994px 0 0 -5px #f7f7f7, 995px 0 0 -5px #f7f7f7,
    996px 0 0 -5px #f7f7f7, 997px 0 0 -5px #f7f7f7, 998px 0 0 -5px #f7f7f7, 999px 0 0 -5px #f7f7f7,
    1000px 0 0 -5px #f7f7f7;
  transition: background-color 150ms;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #1e22ff;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  margin-top: -7px;
}

input[type="range"]::-ms-fill-lower {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
  background: linear-gradient(
    90deg,
    #d63131 0%,
    #ff9b00 5%,
    #ffcc00 10%,
    #00dc6a 25%,
    #277e51 100%
  );
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
  border-radius: 24px;
  background: linear-gradient(
    90deg,
    #d63131 0%,
    #ff9b00 5%,
    #ffcc00 10%,
    #00dc6a 25%,
    #277e51 100%
  );
}

input[type="range"]::-moz-range-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
  border-radius: 24px;
  background: linear-gradient(
    90deg,
    #d63131 0%,
    #ff9b00 5%,
    #ffcc00 10%,
    #00dc6a 25%,
    #277e51 100%
  );
}

.tickmark__lowest {
  position: absolute;
  left: 28%;
  height: 41px;
  /* background: currentColor; */
  width: 0px;
  top: -24px;
  border: 0.6px dashed lightgray;
  user-select: none;
}

.channel__preview-wrapper-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.channel__preview-wrapper-img {
  max-width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  display: block;
  max-height: 100%;
}

#app .channel__preview-wrapper-backdrop {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.2);
}

#app .channel__preview-wrapper {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 100000;
  width: 100%;
  max-width: 1000px;
  background: white;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0%);
  padding: 15px 15px;
  border-radius: 4px;
}

.channel__item-wrapper {
  box-shadow: 0 0 10px rgba(3, 169, 244, 0.2);
  border: 1px solid #1e22ff;
  padding-bottom: 5px;
}

.channel__item-wrapper.editing {
  border: 1px solid #ff9c79;
}

.channel__item-wrapper .tags {
  padding: 5px 20px 30px 5px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  align-self: flex-start;
  background: white;
  z-index: 1000;
}

.channel__item-wrapper .tags .tag {
  width: auto;
  background: #d6e9ff;
  font-size: 12px;
  padding: 10px;
  color: #1269cf;
  font-weight: 500;
}

.channel__item-wrapper .tags .tag.special {
  margin-left: 10px;
  color: #5a9716;
  background: #ebffd7;
}

.channel__item-wrapper .tags .tag.edit {
  color: white;
  background: #ff9c79;
}

.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
}

.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox input[type="checkbox"]:checked ~ label::before {
  color: #7bbe72;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}

.channels__edit_bar {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.channels__edit_bar .edit {
  color: #66a3bb;
  font-size: 12px;
  padding-left: 20px;
}

.channels__edit_bar .edit:hover {
  color: #38c7ff;
  cursor: pointer;
}

.checkbox__wrapper {
  width: 35px;
  height: 100%;
  display: flex;
  padding-left: 15px;
  padding-top: 10px;
}

.checkbox__wrapper .styled-checkbox {
  z-index: 100000;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.checkbox__wrapper .styled-checkbox + label:before {
  width: 20px;
  height: 20px;
}

.checkbox__wrapper .styled-checkbox:checked + label:after {
  left: 5px;
  top: 9px;
}

.channel_wrapper .remove {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}

.channel_wrapper .remove h4 {
  font-size: 12px;
  font-weight: 100;
  color: #ff9067;
  margin: 0px;
  cursor: pointer;
}

.channel_wrapper .remove h4:hover {
  color: #faece7;
}

.channel_wrapper .remove.add h4 {
  color: #73abc1;
}

.channel_wrapper .remove.add h4:hover {
  color: #285769;
}

.remove .remove_warning {
  position: absolute;
  font-size: 10px;
  top: 55px;
  width: 100px;
  color: #be6464;
  padding: 5px;
  left: -30px;
  text-align: center;
}

.add_warning {
  font-size: 10px;
  width: 148px;
  color: #be6464;
  padding: 5px;
  text-align: center;
  background: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 9px;
}

.add_warning .text {
  line-height: 1.3;
  position: relative;
  top: -5px;
  font-size: 9px;
}

.add_warning .action {
  background: #2e79d4;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: 700;
  line-height: 2;
  cursor: pointer;
}

.add_warning .action:hover {
  background: #4499ff;
}

.channel__preview-wrapper-content {
  display: flex;
}

.channel__preview-wrapper-content-channels {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.channel__preview-wrapper-content-channels div {
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.channel__preview-wrapper-content-channels .selected {
  background: #3f567d;
  color: white;
}

.channel__preview-wrapper-content-channels div:hover {
  background: #86a8e3;
}

.channel__preview-wrapper-content-channels .selected:hover {
  background: #3f567d;
}

.channel__preview-wrapper-content-previews {
  width: 100%;
  max-height: 500px;
}

/*  ---_--------_----- Notifications  ---_--------_----- */

.notification_wrapper {
  max-width: 80%;
  height: 68px;
  /* border: 1px solid; */
  margin: auto;
  box-shadow: 0 0 0 1px rgba(2, 2, 12, 0.05), 0 2px 4px -2px rgba(2, 2, 12, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(2, 2, 12, 0.05), 0 2px 4px -2px rgba(2, 2, 12, 0.1);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 60%;
}

.notification_wrapper_left {
  display: flex;
  align-items: center;
  flex: 1;
}

.notification_wrapper_left__line {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8dc572;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 10px;
  color: white;
}

.notification_wrapper_right .actions {
  height: 43px;
  border-radius: 999px;
  font-size: 13px;
  letter-spacing: -0.15px;
  padding: 14px 22px 16px 22px;
  line-height: 1;
  min-width: 68px;
  border: none;
  box-shadow: 0 0 0 1px rgba(2, 2, 12, 0.05), 0 2px 4px -2px rgba(2, 2, 12, 0.1);
  margin: 10px 10px;
}

.notification_wrapper_right .actions:hover {
  cursor: pointer;
}

.notification_wrapper_right .actions.primary {
  background-color: rgb(255, 153, 0);
  color: white;
}

.single_notification__wrapper {
  width: 45%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.single_notification__bar {
  background: white;
  height: 100px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.single_notification__bar .button {
  height: 43px;
  border-radius: 999px;
  font-size: 13px;
  letter-spacing: -0.15px;
  padding: 14px 22px 16px 22px;
  line-height: 1;
  min-width: 68px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  margin: 5px;
  color: #000;
}

.single_notification__bar .button.primary {
  background-color: #00be72;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
}

.single_notification__bar .button:hover {
  color: black;
  background: antiquewhite;
  cursor: pointer;
}

.single_notification__bar .button.primary:hover {
  background: #007b4a;
  cursor: pointer;
  color: white;
}

.single_notification__bar .circle {
  background: #ff9800;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 20px;
}

.single_notification__bar .campaign_data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.single_notification__bar .button_wrapper {
  display: flex;
}

.fb-comment-wrapper {
  max-width: 600px;
  background: #f2f3f5;
  border-radius: 15px;
  padding: 5px 10px 5px 20px;
  background-color: #f2f3f5;
  border-radius: 18px;
  box-sizing: border-box;
  color: #1c1e21;
  display: inline-block;
  line-height: 16px;
  margin: 0;
  word-wrap: break-word;
  position: relative;
  white-space: normal;
  word-break: break-word;
  margin: 10px;
  min-width: 350px;
}

.fb-comment-wrapper .fb-comment-user {
  color: #385898;
  cursor: pointer;
  text-decoration: none;
}

.fb-comment-wrapper .fb-comment {
  white-space: normal;
  word-break: break-word;
  color: #1c1e21;
}

@media (max-width: 900px) {
  .campaign-read__list {
    display: none;
  }
  .campaign-read__wrapper.campaign-read__wrapper {
    left: 0px;
    width: 100%;
  }
  .campaign-read__insights.campaign-read__insights {
    padding: 5px;
  }
  .campaign-read__filters.campaign-read__filters {
    display: none;
  }
  .campaign-read__social-preview.campaign-read__social-preview {
    display: none;
  }
  .campaign-read__social-container.campaign-read__social-container {
    width: 100%;
  }
}

@media (max-width: 695px) {
  .campaign-read__container.campaign-read__container {
    width: 100%;
  }
  .campaign-read__activity.campaign-read__activity {
    position: initial !important;
  }
}

.button--strong.button--top {
  float: right;
}

.preview__images {
  margin-bottom: 10px;
}

.preview__adimage {
  max-width: 100px;
  margin-right: 10px;
}

.clear {
  clear: both;
}

.flex__elememt__70 {
  width: 70%;
  padding-right: 40px;
}

.flex__elememt__30 {
  width: 30%;
}

.flex__elememt__50 h2 {
  margin-top: 10px;
}

.flex__elememt__50 {
  max-width: 500px;
  padding: 20px;
  padding-top: 0px;
}

.channel-list {
  margin: 20px 20px;
  color: gray;
}

.dashboard__orgas select {
  background: rgba(0, 0, 0, 0.1);
}

.preview__channel {
  margin-right: 20px;
}

.public-DraftStyleDefault-ul {
  list-style-type: disc;
  margin-left: 20px;
}

.preview__plattform--headline:hover {
  background: #c5dae6;
}

.message-to-client {
  margin: 0 auto;
  margin-top: 10px;
  background: white;
  padding: 20px;
  font-size: 15px;
  border: 1px solid #adadad;
  border-radius: 5px;
  max-width: 400px;
  line-height: 1.4em;
  box-shadow: 0 0 20px 1px #d0d0d0;
}

.preview__plattform:not(.preview__plattform--closed) .preview__plattform--headline {
  background: #004cff;
  color: white;
}

.preview__plattform--headline {
  background: #e3edf3;
  padding: 10px;
  color: #9c9c9c;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 12px;
  z-index: 100000;
}

.preview__plattform--headline h3 {
  margin: 0px;
  font-size: 14px;
}

.preview__plattform--body {
  /* background: #fbfbfb; */
  position: relative;
}

.upload-btn-wrap {
  font-weight: 400;
  background: #00000008;
  border-radius: 14px;
  padding: 5px 7px;
  margin: 11px 0px;
  display: inline-block;
  color: #1e22ff;
  cursor: pointer;
  user-select: none;
}

.upload-btn-wrap label {
}

.upload-btn-wrap:hover {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-file-upload-button {
  visibility: hidden;
}

.section-fallback {
  height: 40px;
  padding: 0px 10px;
  line-height: 40px;
  background: lightgray;
}

.preview__plattform--body.preview__plattform--body--closed {
  display: none;
}

.label__adset {
  position: absolute;
  top: 4px;
  right: 10px;
}

.label__wrap {
  position: relative;
}

.public-DraftEditor-content {
  min-height: 100px;
  background: white;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 300px;
}

.preview__upload-photo {
  border: 1px dashed rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
  width: 100px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 3px;
}

.preview__input.preview__input--textarea {
  height: 60px;
}

.section__label {
  color: #151633;
  display: inline-block;
  margin-bottom: 7px;
  margin-top: 7px;
  /* background: #e1e1e1; */
  border-radius: 20px;
  padding: 0px;
  font-size: 13px;
  margin-top: 13px;
  margin-bottom: 9px;
  text-transform: uppercase;
  font-weight: bold;
}

.preview__input {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  padding: 10px 10px;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.preview__teaser-text {
  height: 100px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.example-ad {
  overflow: hidden;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: 0 auto;
  min-height: 300px;
  padding: 20px;
  border-radius: 3px;
  background: white;
}

.nav__note {
  background: red;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
  margin-left: 16px;
  display: inline-block;
  padding-top: -16px;
  position: relative;
  top: -3px;
  color: white;
  font-weight: 500;
}

.preview__modal textarea {
  width: 100%;
  padding: 10px;
}

.preview__modal {
  position: fixed;
  top: 90px;
  width: 800px;
  height: 500px;
  background: white;
  padding: 40px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.4);
}

.button-bar__bar.button-bar__bar--center {
  text-align: center;
}

.button-bar__btn {
  text-decoration: underline;
}

.button-bar__bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: white;
  padding: 10px;
  box-shadow: 0px -1px 4px 3px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.public__preview-cta {
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;
  text-align: center;
  background: #5bc04c;
  color: white;
  border-radius: 3px;
  border: 0px;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
}

.public__preview-cta.public__preview-cta--image-upload {
  background: green;
}

.public__preview-cta.public__preview-cta--reject {
  width: 200px;
  background: gray;
  color: white;
}

.bubble-long {
  display: block;
  white-space: nowrap;
}

.bubble.bubble--warning {
  background: #ffe500;
}

.bubble {
  background: #c7c7c7;
  padding: 3px 5px;
  border-radius: 28px;
  font-size: 11px;
  color: #3e3e3e;
}

.bubble.bubble__red {
  background: red;
}

.approval-bar__wrapper {
  position: relative;
}

.approval-bar {
  position: absolute;
  right: 30px;
  top: 50%;
  width: 200px;
}

.approval-bar__textarea {
  padding: 10px;
  width: 100%;
}

.feedback__wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  background: white;
  padding: 14px;
  border: 4px solid red;
}

.feedback__wrap.feedback__wrap--accepted {
  border: 4px solid #8bc34a;
}

.settings-line-item {
  margin-bottom: 12px;
}

.list-editor-item__answer-link {
  color: blue;
}

.list-editor-item__label-1 {
  font-weight: bold;
  margin-bottom: 5px;
  display: inline-block;
}

.list-editor-item__answer-add {
  background: #2ba5ff;
  color: white;
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 5px 5px;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s all;
}

.list-editor-item__answer-add:active {
  transform: translateY(2px);
}

.tb-new__quick-access {
  height: 56px;
  display: block;
  width: 100%;
  line-height: 56px;
  color: #928fa3;
  user-select: none;
  font-weight: 600;
  padding: 0px 24px;
  transition: all 0.2s;
  position: relative;
}

.tb-new__quick-access i {
  margin-right: 16px;
}

.tb-new__quick-access__edit {
  position: absolute;
  top: 7px;
  right: -29px;
  max-width: 30px;
  cursor: pointer;
}

.tb-new__quick-access__edit.tb-new__quick-access__edit--small {
  position: absolute;
  top: -5px;
  right: -29px;
  max-width: 25px;
  cursor: pointer;
}

.tb-new__quick-access__h4 {
  font-size: 16px;
  margin-bottom: 15px;
  transition: background 0.2s;
  padding: 0px 6px;
}

.tb-new__quick-access__h4:hover {
  background: #eee;
}

.relative-fit {
  position: relative;
  width: fit-content;
  height: min-content;
}

.tb-new__quick-access__h1 {
  font-size: 24;
  font-weight: 700;
  width: fit-content;
  margin-bottom: 5px;
  transition: background 0.2s;
  padding: 10px 6px;
}

.tb-new__quick-access__h1:hover {
  background: #eee;
}

.tb-new__quick-access img {
  float: right;
  position: absolute;
  right: 10px;
  top: 22px;
  opacity: 0;
  transition: all 0.2s;
}

.tb-new__quick-access:hover img {
  opacity: 1;
  right: 15px;
}

.tb-new__quick-access:hover {
  background: #cdc5ec;
  color: #8066ff;
}

.tiktok__subscribe {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fe2b54;
  color: white;
  font-weight: 700;
  right: 12px;
  top: 222px;
  z-index: 10;
  border-radius: 20px;
  text-align: center;
  line-height: 15px;
}

.list-editor-item__close {
  font-size: 11px;
  float: right;
  position: relative;
  left: -5px;
  top: -4px;
  cursor: pointer;
  /* background: blue; */
  display: block;
  padding: 5px;
}

.list-editor-item__close:hover {
  color: black;
}

.list-editor-item__option {
  background: #dee3ee;
  color: gray;
  border-radius: 5px;
  padding: 5px 10px;
  display: block;
  margin-bottom: 5px;
}

.list-editor-item {
  margin-top: 5px;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.list-editor-item__circle {
  /* width: 20px; */
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #adadad;
  color: white;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 11px;
  padding: 0px 10px;
}

.list-editor-item__input {
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 10px;
  padding: 0px 10px;
}

.list-editor-item__input.list-editor-item__input--question {
  width: 150px;
}

.list-editor-item__input.list-editor-item__input--question--long {
  width: 100%;
  margin-bottom: 10px;
}

.approval-bar__yes,
.approval-bar__no,
.approval-bar__download {
  background: gray;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: 600;
  transition: border 0.3s;
}

.approval-bar__note {
  max-width: 200px;
}

.approval-bar__yes {
  border: 2px solid #5bc04c;
  background: transparent;
  color: #5bc04c;
}

.approval-bar__yes:hover {
  border: 2px solid #409444;
}

.approval-bar__download {
  background: transparent;
  color: #d8b234 !important;
}

.approval-bar__download:hover {
  background: transparent;
  color: #917827 !important;
}

.sticky-co {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  left: -30px;
  z-index: 1000;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.second-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: -1px;
  z-index: 1000;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.input__checkbox {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.first-col.sticky-co--first {
  left: -30px;
  z-index: 10000000;
}

.second-col.sticky-co--first {
  left: -1px;
  z-index: 10000000;
}

.approval-bar__no {
  background: #f44336;
}

.approval-bar__edit {
  background: #cdcdcd;
  height: 40px;
  text-align: center;
  border-radius: 3px;
  line-height: 40px;
  color: #4f4f4f;
  cursor: pointer;
  transition: all 0.3s;
}

.approval-bar__edit:hover {
  background: #a2a2a2;
  color: black;
}

.approval-bar__no:hover {
  background: #984c46;
}

.approval-bar__wrapper--container {
  background: gray;
  width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
}

.approval-bar__wrapper--container img {
  width: 400px;
  display: block;
  margin: 0 auto;
}

.signup-headline__wrapper {
  margin-right: 50px;
}

.anmelden__logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 150px;
}

.anmelden__check {
  max-width: 30px;
  max-width: 22px;
  vertical-align: top;
  margin-right: 10px;
}

.anmelden__ul {
  line-height: 1.7em;
  font-size: 14px;
  color: #999999;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  text-align: left;
}

.anmelden__ul li {
  padding: 10px 0;
  list-style-type: none;
  border-bottom: 1px solid #e4eaef;
}

.anmelden__ul li:last-child {
  border-bottom: none;
}

.anmelden__p {
  font-size: 14px;
  color: #999;
  padding-top: 20px;
  line-height: 1.6em;
  font-weight: 300;
}

.budget-cell:hover .budget-cell__action-bar {
  opacity: 1;
  pointer-events: all;
}

.budget-cell__action-bar {
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}

.active_status {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 7px;
  width: 7px;
  border-radius: 10px;
  background: yellow;
}

.active_status.active_status--ACTIVE {
  background: #53c11e;
}

.active_status.active_status--LEARNING {
  background: #f9c440;
}

.active_status.active_status--FAIL {
  background: #ff605f;
}

.active_status.active_status--FINISHED {
  background: #1e22ff;
}

.page-break {
  page-break-after: always;
}

.tab-wrapper {
  padding: 20px;
}

@media print {
  .button-bar__bar {
    display: none;
  }
  .approval-bar {
    display: none;
  }
}

.tab-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
}
.nav-tab {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding: 10px 25px;
  text-decoration: none;
  color: #928fa3;
}

/* .nav-tab:hover {
    background-color: oldlace;
} */

.nav-tab::after {
  content: "";
  display: block;
  border-radius: 3px;
  width: 0%;
  height: 2px;
  top: 5px;
  position: relative;
  transition: all 0.2s;
  background: #8066ff;
}

.nav-tab:hover::after {
  width: 100%;
}

.nav-tab.active::after {
  width: 100%;
}

.nav-tab.active {
  cursor: default;
  color: #302e38;
  background: transparent;
  border: 0;
  font-weight: 600;
}

.score-vis__wrap-img {
  position: relative;
  text-align: center;
}
.score-vis__wrap-img svg:not(:root) {
  overflow: visible !important;
}
.score-vis__wrap-img .score-vis__rotate {
  transform-origin: center;
  position: absolute;
  top: 44%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.score-vis__wrap-score-postfix {
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  letter-spacing: 0.64px;
  text-align: center;
  transition: color 0.1s;
}

.score-vis__wrap-score {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  letter-spacing: 0;
  text-align: center;
  transition: color 0.3s;
}

.suggestion-pannel__empty_state .score-vis__rotate {
  -webkit-transform: rotate(0deg);
  animation: rotation 4s linear infinite;
  animation-play-state: paused;
}
.suggestion-pannel__empty_state.suggestion-pannel__empty_state--rotate .score-vis__rotate {
  animation: rotation 4s linear infinite;
  animation-play-state: running;
}

.suggestion-pannel__empty_state .headline {
  margin: 20px 0px;
  font-weight: 100;
  /* border-bottom: 1px solid #888; */
  font-size: 24px;
  font-weight: bold;
}

.suggestion-pannel__empty_state .description {
  font-size: 15px;
  line-height: 1.4em;
  font-weight: 100;
  margin: 5px 0px;
}

.suggestion-pannel__empty_state .link {
  font-size: 15px;
  line-height: 1.4em;
  font-weight: 100;
  margin: 5px 0px;
  color: #0b24fb;
  text-decoration: underline;
}

.checkbox-field {
  display: flex;
}

.checkbox-field .label-text {
  position: relative;
  top: 4px;
}

#right-side-portal {
  position: absolute;
  top: 0;
  right: 0;
  width: 420px;
  height: 93%;
  z-index: 1;
}

#right-side-portal:empty {
  z-index: -1;
  display: none;
  pointer-events: none;
}