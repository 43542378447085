/* .signup-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F2F7FA;
    z-index: 10000;
    padding: 20px;
} */
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
.signup h1 {
  color: #1e22ff;
  font-size: 33px;
  margin: 50px 0 0 0;
}

.signup-popup__paragraph {
  font-size: 20px;
}

/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

.signup-popup.is-creating .signup-popup__section {
  opacity: 0.5;
  pointer-events: none;
}

.signup-popup__btn-back {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 12px;
  cursor: pointer;
}
.signup-popup__password-redirect {
  color: #1e22ff;
  text-decoration: underline;
}
.signup-popup__logo {
  /* text-align: center; */
}
.signup-popup__btn-back:hover {
  text-decoration: underline;
}

.signup-popup__wrapper-new {
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  width: 800px;
  margin: 0 auto;
  margin-top: 100px;
}

.signup-popup__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}
.signup-popup__form {
  max-width: 400px;
  margin: 0 auto;
}
.signup-popup h2 {
  color: #1e22ff;
}

.signup-popup__list {
  text-align: left;
}
