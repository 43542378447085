.input-range {
  height: 1rem;
  position: relative;
  margin: 20px;
  margin-bottom: 40px;
}

.input-range {
  margin-top: 40px;
  margin-bottom: 70px;
}
.input-range__slider {
  position: absolute;
  top: 50%;

  display: block;

  width: 1rem;
  height: 1rem;
  margin-top: -0.65rem;
  margin-left: -0.5rem;

  cursor: pointer;
  -webkit-transition: box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out, -webkit-transform 0.3s ease-out;

  border: 1px solid #48c651;
  border-radius: 100%;
  outline: none;
  background: #48c651;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-range__slider:active {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  -webkit-transform: none;
  transform: none;

  border: 1px solid #ccc;
  background: #ccc;
  box-shadow: none;
}

.input-range__slider-container {
  -webkit-transition: left 0.16s ease-out;
  transition: left 0.16s ease-out;
}

.input-range__label {
  font-size: 0.8rem;

  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  position: absolute;
  bottom: -1.4rem;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  position: relative;
  left: -50%;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  position: relative;

  display: block;

  height: 0.3rem;

  cursor: pointer;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;

  border-radius: 0.3rem;
  background: #eee;
}
.input-range--disabled .input-range__track {
  background: #eee;
}

.input-range__track--background {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  margin-top: -0.15rem;
}

.input-range__track--active {
  background: #48c651;
}
