.form {
  margin: 0 auto;
  padding: 100px 30px 0;
}

.form__input-container {
  position: relative;
}
.form__example-string {
  position: absolute;
  top: 23px;
  right: 20px;
  font-size: 12px;
  opacity: 0.2;
  pointer-events: none;
}

.form__example-string.change {
  opacity: 1;
}

.form__example-string a {
  pointer-events: all;
  color: #1e22ff;
  text-decoration: underline;
  opacity: 1;
}

@media (min-width: 800px) {
  .form {
    max-width: 60%;
  }
}
