/*------------------------------------*    REVEAL ANIMATION
\*------------------------------------*/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    /* -webkit-transform: translate(0px, 0px); */
  }
  to {
    opacity: 1;
    /* -webkit-transform: translate(0px, 0px); */
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
    /* -moz-transform: translate(0px, 0px); */
  }
  to {
    opacity: 1;
    /* -moz-transform: translate(0px, 0px); */
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translate(0px, 0px); */
  }
  to {
    opacity: 1;
    /* transform: translate(0px, 0px); */
  }
}
.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in-out 1;
  -moz-animation: fadeIn ease-in-out 1;
  animation: fadeIn ease-in-out 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  /* -webkit-transform: translate(0px, 0px); */
}
.fade-in.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.fade-in.two {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.fade-in.three {
  -webkit-animation-delay: 0.85s;
  -moz-animation-delay: 0.85s;
  animation-delay: 0.85s;
}
.fade-in.four {
  -webkit-animation-delay: 0.95s;
  -moz-animation-delay: 0.95s;
  animation-delay: 0.95s;
}
.fade-in.five {
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
  animation-delay: 1.05s;
}
.fade-in.six {
  -webkit-animation-delay: 1.15s;
  -moz-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
.fade-in.seven {
  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  animation-delay: 1.25s;
}
.fade-in.eight {
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
  animation-delay: 1.35s;
}
.fade-in.nine {
  -webkit-animation-delay: 1.45s;
  -moz-animation-delay: 1.45s;
  animation-delay: 1.45s;
}
.fade-in.ten {
  -webkit-animation-delay: 1.55s;
  -moz-animation-delay: 1.55s;
  animation-delay: 1.55s;
}
.fade-in.eleven {
  -webkit-animation-delay: 1.65s;
  -moz-animation-delay: 1.65s;
  animation-delay: 1.65s;
}
.fade-in.twelve {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  animation-delay: 1.75s;
}
