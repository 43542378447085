.location-select__wrapper {
  width: 100%;
}

.location-select__wrapper-components {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* 4 Inputs and their details -- start */
.location-select__searchbar-area {
  position: relative;
}

.location-select__location-search-input {
  display: block;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  font-size: 14px;
  font: 400 13.3333px Arial;
  overflow: visible;
  line-height: 1.15;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  border: 1px solid #dee3de;
  padding-left: 30px;
  margin-bottom: 18px;
}

.location-select__location-search-input:focus {
  /* box-shadow: 0 0 10px rgba(3 169 244 / 20%); */
  border: 1px solid #1e22ff;
  outline: 0px;
}

.location-select__location-search-error-input {
  display: block;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  font-size: 14px;
  font: 400 13.3333px Arial;
  overflow: visible;
  line-height: 1.15;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  border: 1px solid red;
  padding-left: 30px;
  margin-bottom: 18px;
}

.location-select__show-map-icon {
  position: absolute;
  /* added */
  top: 18px;
  right: 6px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.location-select__show-map-label {
  font: 400 10px Arial;
}

.location-select__error {
  position: absolute;
  color: red;
  font: 400 13.3333px Arial;
  margin-top: -15px;
}

/* 4 Inputs and their details -- end */

/* Dropdown suggestions -- start */

.location-select__autocomplete-dropdown-container {
  position: absolute;
  margin-top: -18px;
  background-color: white;
  line-height: 30px;
  border: 1px solid #1e22ff;
  border-radius: 5px;
  width: 660px;
  z-index: 99999999999;
}

.location-select__autocomplete-dropdown-container:empty {
  display: none;
}

.location-select__dropdown-elements {
  border-bottom: 0.1px solid rgb(177, 169, 169);
  font: 400 15px Arial;
  padding: 10px;
}

.location-select__dropdown-elements:hover {
  background-color: rgb(213, 203, 203);
  cursor: pointer;
}

.location-select__loading-bar {
  position: absolute;
  background-color: white;
  line-height: 30px;
  width: 660px;
  /* added */
  margin-left: -1px;
  border-right: 1px solid #1e22ff;
  border-left: 1px solid #1e22ff;
  border-bottom: 1px solid #009bff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font: 400 15px Arial;
  padding: 10px;
}

/* Dropdown suggestions -- end*/

/* Selected Elements -- start */

.location-select__selected-element {
  /* background-image: url("../../../../../public/images/flag__germany.svg") no-repeat scroll 5px 6px; */
  border: none;
  background-color: #dee3ee;
  margin-top: 2px;
  margin-bottom: 8px;
  border-radius: 4px;
  min-width: 400px;
  height: 25px;
  font: 400 14px Arial;
  padding-left: 25px;
  position: relative;
  color: gray;
}

.location-select__selected-locations {
  padding-top: 5px;
  line-height: 40px;
}

.location-select__remove-button {
  border: none;
  height: 100%;
  float: right;
  position: relative;
  top: 4px;
  opacity: 0.5;
  left: -10px;
}

.location-select__remove-button-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.location-select__radius-select-box {
  color: gray;
  margin-left: 15px;
  height: 100%;
  background-color: #dee3ee;
  border: none;
}
.location-select__radius-select-box:hover {
  cursor: pointer;
  opacity: 0.7;
}
.location-select__radius-select-box:focus {
  border: none;
}

.location-select__money-label {
  padding-right: 10px;
  color: green;
}

.location-select__state-country {
  margin-right: 5px;
}

.location-select__flag-icon {
  position: absolute;
  margin-left: -20px;
  padding-top: 6px;
  width: 15px;
  height: 20px;
}

.location-select__search-icon {
  position: absolute;
  margin-top: 22px;
  margin-left: 10px;
}

/* Selected Elements -- end */

/* API style -- start- */

.gm-style-mtc {
  display: none;
}

.gm-svpc {
  display: none;
}

.gm-fullscreen-control {
  height: 20px !important;
  width: 20px !important;
}

.gm-style {
  overflow: visible;
}

/* API style -- end */
