/* apply a natural box layout model to all elements, but allowing components to change */
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background: #f2f5f7;
  color: black;
  font-size: 13px;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}

/* Notifications */

.notifications-wrapper {
  position: fixed;
  top: 0px;
  z-index: 10000000000000;
}

/* Headlines */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h2 {
  font-weight: 400;
  margin: 17px 0px;
  margin-top: 43px;
}

h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  margin-top: 15px;
}

/* Other HTML elements */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(191, 191, 191, 0.3);
  margin: 1em 0;
  padding: 0;
  width: 50%;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

/* Links */

a.link {
  cursor: pointer;
  color: #1e22ff;
  text-decoration: underline;
}

a {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #444;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

/* Selection */

::-moz-selection {
  color: white;
  background: #1e22ff;
}
::selection {
  color: white;
  background: #1e22ff;
}

/* Misc */

.wrapper {
  position: relative;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
}

.section__wrapper {
  background: #eaecf5;
  color: #586368;
  text-align: center;
  padding: 120px 0 0 0;
  min-height: calc(100vh - 122px);
}

.section__wrapper.section__wrapper--small {
  background: #eaecf5;
  color: #586368;
  text-align: center;
  padding: 0px 0;
  min-height: 100vh;
}

.section__wrapper.generator {
  background: transparent;
  padding: 50px 0 0 0;
}

.sidepane__btn-back {
  cursor: pointer;
}

.payment {
  max-width: 700px;
  margin: 0 auto;
}

.list {
  line-height: 1.6em;
}

.breadcrumb {
  position: absolute;
  top: 83px;
  left: 30px;
  color: #9ca5d4;
}

.breadcrumb__title {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 4px;
}

.breadcrumb a {
  color: #9ca5d4;
  text-decoration: underline;
}

/* Overwrites Intercom bubble position */
.intercom-overwrite .intercom-namespace div,
.intercom-namespace span,
.intercom-namespace iframe {
  margin-bottom: 65px !important;
}

.intercom-overwrite .intercom-lightweight-app-launcher {
  bottom: 65px !important;
}

.intercom-namespace-hover div,
.intercom-namespace-hover span,
.intercom-namespace-hover iframe {
  z-index: 2 !important;
}
/*****/

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    margin: 0;
    /* this affects the margin in the printer settings */
  }

  body,
  html {
    width: 100%;
    height: 100%;
    margin-top: 0%;
    display: block;
    margin-top: 0;
    overflow: scroll;
    max-height: 100vh;
  }

  .no-print {
    display: none;
  }

  footer {
    display: none;
  }
}
