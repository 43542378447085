.expert__name {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}
.expert__name .user__position {
  color: #444;
  margin-top: 2px;
  display: block;
  font-size: 13px;
}
.expert__img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.campaign-read__in-progress-wrapper .expert {
  margin-top: 5px;
}

.comments__fallback-msg {
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

.comments__comment.comments__comment--admin {
  background: #e3e3e6;
  color: #656565;
}
.comments__comment {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  position: relative;
}

.comments__note {
  border-radius: 3px;
  margin-bottom: 10px;
  position: relative;
  background: #ffffff75;
  border-radius: 3px;
  padding: 11px;

  max-width: 600px;
}

.comments__note:hover {
  background: white;
}

.comments__comment-image {
  width: 20px;
  height: 20px;
  background: red;
}
.comments__comment-date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}
.comments__comment-author {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  margin-right: 5px;
}
.comments__comment-content {
  display: block;
  margin: 7px 0 0 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 17px;
}
.conversation__btn {
  width: 35px;
  outline: 0;
  border: 0;
  background: transparent;
  height: 27px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 22px;
  opacity: 0.3;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  height: 35px;
  vertical-align: middle;
  line-height: 9px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.conversation__btn svg {
  margin: 2px 2px 1px 1px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.conversation__btn svg path {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.conversation__upload {
  font-weight: bold;
  background: #00000008;
  border-radius: 14px;
  padding: 5px 7px;
  margin: 11px 0px;
  display: inline-block;
  color: #2196f3;
}

.conversation__btn:hover,
.conversation__btn.conversation__btn--active {
  border: 1px solid #03a9f4;
  opacity: 1;
  background: #03a9f4;
}

.conversation__btn.conversation__btn--active svg path {
  fill: #fff;
}

.conversation__btn:hover svg path {
  fill: #fff;
}
.conversation__post {
  margin: -20px -20px 0 -20px;
  z-index: 9999;
  position: relative;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.conversation__post textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 15px;
  min-height: 80px;
  border-bottom: 1px solid #e0e6ea;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  border-radius: 0;
  padding: 25px 65px 25px 25px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 13px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.conversation__feed {
  margin: -20px;
  background: rgba(242, 245, 247, 0.2);
}
.conversation__feed .comments__comment {
  padding: 20px 25px;
  border-bottom: 1px solid #e0e6ea;
  margin-bottom: 0;
}
.user__state {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 7px;
  font-weight: 400;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  color: #ffb40b;
}
.user__state:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 5px;
  border-radius: 100%;
  background: #ffb40b;
}
.user__state--online {
  color: #8bc34a;
}
.user__state--online:before {
  background: #8bc34a;
}
.user_state--offline {
  color: #37517e;
}
.user_state--offline:before {
  background: #37517e;
}
.comments__comment .button--float {
  opacity: 0;
}
.comments__comment:hover .button--float {
  opacity: 1;
}
