/* navitator */
.settings__navigator {
  font-family: "Rubik", sans-serif !important;
  position: relative;
  width: 20%;
  height: calc(100vh - 85px);
  position: relative;
  background-color: white;
  /* layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  /* overflow */
  position: sticky;
  top: 85px;
  left: 0px;
  height: calc(100vh - 85px);
}
.settings__navigator-links-container {
  /* padding: 0 20px; */
  margin: 100px 0;
  width: 100%;
  text-align: left;
  /* layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.settings__navigator-link {
  display: block;
  width: 65%;
  padding: 16px 0;
  padding-left: 20px;
  /* color */
  font-size: 15px;
  color: rgb(55, 81, 126);
  /* border */
  border-left: 4px solid transparent;
}
.settings__navigator-link:hover {
  /* background-color: rgba(179, 185, 209, 0.4); */
  background-color: #f2f5f7;
}
.settings__navigator-link.selected {
  background-color: #f2f5f7;
  /* color: white; */
  border-left: 4px solid #1e22ff;
}

/* profile */
.settings__selected {
  min-height: calc(100vh - 85px);
  /* layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.settings__selected-content {
  margin: 100px 0;
  position: relative;
  width: 80%;
}
.settings__selected-content.is-creating .signup-popup__section {
  opacity: 0.5;
  pointer-events: none;
}
.settings__selected-content-wrapper {
  font-family: "Rubik", sans-serif !important;
  margin: 0 auto;
  width: 400px;
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; */
}
.textarea.input.settings {
  max-width: 100%;
  min-width: 100%;
}
.settings__selected .headline__h1 {
  width: 400px;
  margin: 0 auto;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
}
.settings__selected .cta.signup-popup__section {
  /* font-weight: 400; */
  font-family: "Rubik", sans-serif;
}

/* select */
.settings__select {
  display: block;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 50%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.settings__select::-ms-expand {
  display: none;
}
.settings__select:hover {
  border-color: #888;
}
.settings__select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.settings__select option {
  font-weight: normal;
}

/* list */
.settings__list {
  /* padding: 10px 20px; */
  margin: 0 auto;
}
.settings__list li {
  background-color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  width: 100%;
  /* layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.settings__list li .settings__list-leave {
  color: #f44336;
  font-size: 14px;
  cursor: not-allowed;
  transition: display 0.2s;
  display: none;
}
.settings__list li:hover .settings__list-leave {
  display: block;
}

.settings__list li .settings__list-enter {
  display: none;
}

.settings__list li:hover .settings__list-enter {
  display: block;
}
.settings__list li:hover .settings__list-leave {
  display: block;
}

/* integration */
.settings__selected-content-wrapper.settings__selected-content-wrapper--integration {
  min-width: 400px;
  width: 70%;
}
.settings__selected
  .settings__selected-content-wrapper.settings__selected-content-wrapper--integration
  .headline__h1 {
  /* width: 100%; */
  text-align: center;
  margin-bottom: 50px;
}
.settings__integration-cards-container {
  width: 100%;
  /* layout */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.settings__integration-card {
  position: relative;
  opacity: 0.4;
  border-radius: 5px;
  background-color: white;
  height: 200px;
  width: 30%;
  padding: 15px;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
  color: rgb(55, 81, 126);
  text-align: center;
  transition: all 0.3s;
  /* layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.settings__integration-card:hover {
  opacity: 0.8;
}
.settings__integration-card-coming-soon {
  border-radius: 7px;
  background-color: rgb(55, 81, 126);
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  /* position */
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  /* visibility */
  visibility: hidden;
  transition: visibility 0.1s;
}
.settings__integration-card:hover .settings__integration-card-coming-soon {
  visibility: visible;
}
.settings__integration-card-logo {
  height: 60px;
}
.settings__integration-card-logo.settings__integration-card-logo--small {
  height: 30px;
}
.settings__integration-card-name {
  color: #181d2e;
  /* font-family: 'Rubik', sans-serif; */
  font-size: 16px;
}
.settings__integration-card-join-button {
  color: white;
  margin: 4px 2px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  /* font-family: 'Rubik', sans-serif; */
  cursor: not-allowed;
  /* height: ; */
  transition: all 0.2s;
  background-color: rgb(255, 186, 0);
  border: 1px solid rgb(255, 186, 0);
}
.settings__integration-card-join-button:hover {
  color: rgb(255, 186, 0);
  background-color: transparent;
  border: 1px solid rgb(255, 186, 0);
}

/* team-members */
.settings__selected div.section__wrapper.fade-in {
  padding-top: 0px;
  background-color: #f2f5f7;
}
.settings__selected .invite-members__member:nth-child(even) {
  background-color: white;
}
.beamer_defaultBeamerSelector {
  width: 35px !important;
  height: 35px !important;
}
